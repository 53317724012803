import { Button, Card, Tooltip } from "antd";
import { PlantStatus } from "./PlantStatus";
import { StyleGuide } from "styles/StyleGuide";
import { AlertOutlined } from "@ant-design/icons";
import { format } from "date-fns";
import { useState } from "react";
import { IOrg } from "modules/orgs/interfaces";

export function PlantCard({
    plant,
    lastSeen,
    org,
}: {
    plant: any;
    lastSeen: number | null;
    org: IOrg | null;
}) {
    const consolidateErrors = () => {
        if (plant.latestData?.formattedAlertsMessages?.length > 0) {
            return plant.latestData.formattedAlertsMessages;
        }
        if (plant.latestData?.errors?.length > 0) {
            return plant.latestData.errors;
        }
        return [];
    };

    const errors = consolidateErrors();

    return (
        <Card>
            <div style={{ display: "flex", alignItems: "center" }}>
                <div
                    style={{
                        position: "absolute",
                        display: "flex",
                        top: 16,
                        left: 16,
                        alignItems: "center",
                        gap: 8,
                    }}>
                    <PlantStatus lastSeen={lastSeen} />{" "}
                    <span
                        style={{
                            fontSize: 12,
                            color: StyleGuide.palette.grey700,
                        }}>
                        Drift status
                    </span>
                </div>
                {/* {errors?.length > 0 && (
                    <div
                        style={{
                            position: "absolute",
                            top: 16,
                            right: 16,
                        }}>
                        <Tooltip
                            title={errors.map((error: string, index: number) => (
                                <div>
                                    {index + 1}. {error}
                                </div>
                            ))}>
                            <AlertOutlined style={{ fontSize: 24, color: "red" }} />
                        </Tooltip>
                    </div>
                )} */}
                <img
                    style={{ width: 100 }}
                    src={require("../../assets/images/icons/gsm-plant.png")}
                />
                <div style={{ wordBreak: "break-word" }}>
                    <div>
                        <span>{org?.title} /</span>{" "}
                        <span style={{ fontWeight: 700 }}>{plant.title}</span>
                    </div>
                    <div>
                        Sidst set:{" "}
                        {lastSeen ? format(lastSeen, "dd/MM/yyyy HH:mm") : "N/A"}
                    </div>
                </div>
            </div>
            <div style={{ marginBottom: 8 }}>
                {errors.map((error: string) => (
                    <div
                        style={{
                            display: "flex",
                            gap: 8,
                            color: StyleGuide.palette.redish,
                            fontSize: 12,
                            alignItems: "center",
                            lineHeight: 1.2,
                            paddingTop: 8,
                            paddingBottom: 8,
                        }}>
                        <AlertOutlined
                            style={{ fontSize: 18, color: StyleGuide.palette.redish }}
                        />
                        {error}
                    </div>
                ))}
            </div>
            {plant.latestData && <PlantData data={plant.latestData} />}
        </Card>
    );
}

function PlantData({ data }: any) {
    const [open, setOpen] = useState(false);

    return (
        <div>
            <div>
                <Button block type="default" onClick={() => setOpen(!open)}>
                    {!open ? "Se seneste data" : "Skjul seneste data"}
                </Button>
            </div>
            <pre
                style={{
                    backgroundColor: StyleGuide.palette.grey100,
                    margin: 0,
                    padding: open ? 8 : 0,
                    borderRadius: 4,
                    height: open ? "auto" : 0,
                    overflow: "hidden",
                    transition: "height 0.3s",
                    whiteSpace: "pre-wrap",
                }}>
                {JSON.stringify(data, null, 2)}
            </pre>
        </div>
    );
}
