import { Button, Modal, Popconfirm, Popover, Table, Tag, Tooltip } from "antd";
import { format } from "date-fns";
import {
    getAdditionalServicesSubscriptionFixedPrice,
    getAdditionalServicesSubscriptionVariablePrice,
    getDiscountFactor,
    getLiterPrice,
    getLiters,
    getSubscriptionPrice,
    useOfferContext,
} from "../Context/OfferContext";
import { OfferStatus, PriceType } from "../Context/offerReducer";
import { StyleGuide } from "styles/StyleGuide";
import { AppOffer } from "hooks/useAppOffers";
import { firestore } from "../../../firebase";
import { ArrowRightOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction, useState } from "react";
import OrgsSelect from "Views/DashboardOverAllContainer/OrgsSelect";
import { IOrg } from "modules/orgs/interfaces";
import FormItem from "antd/es/form/FormItem";
import AcceptForm from "./AcceptForm";
import {
    sweepingGranulesLiterPrice,
    year1Discount,
    year2Discount,
    year3Discount,
} from "../Context/offerConfigs";

import SummaryChart, { AvoidSvgOverflow } from "../SummaryChart";
import { Stats } from "..";
import { useHistory } from "react-router";
import OfferPDF from "Views/AgreementDetail/Offer";
import { useOfferDrawerContext } from "../ContextDrawer/OfferContext";
import SalesBars from "../Graphs/Sales";
import { AdminAndCharlotte } from "components/AdminOnly";
import Loader from "components/Loader";
import { Invoice } from "../Drawer/useInvoices";

function getStatus(status: OfferStatus) {
    switch (status) {
        case OfferStatus.DRAFT:
            return { tag: "Kladde", color: "grey" };
        case OfferStatus.SENT:
            return { tag: "Sendt", color: StyleGuide.palette.processing };
        case OfferStatus.ACCEPTED:
            return { tag: "Accepteret", color: StyleGuide.palette.success };
        case OfferStatus.DECLINED:
            return { tag: "Afvist", color: StyleGuide.palette.redish };
        default:
            return { tag: "unknown", color: "black" };
    }
}

export async function updateOffer(offerId: string, data: Partial<AppOffer>) {
    try {
        if (!offerId) {
            throw new Error("Offer id not found");
        }
        const batch = firestore.batch();
        const appOfferRef = firestore.collection("appOffers").doc(offerId);
        batch.set(appOfferRef, { ...data, updateTime: new Date() }, { merge: true });
        const appOfferHistoryRef = firestore
            .collection("appOffers")
            .doc(offerId)
            .collection("history")
            .doc();

        batch.set(appOfferHistoryRef, { ...data, updateTime: new Date() });

        await batch.commit();
    } catch (error) {
        console.error(error);
    }
}

function PopContent({
    offerId,
    setCompleteModal,
    closePop,
}: {
    offerId: string;
    setCompleteModal: Dispatch<SetStateAction<string | null>>;
    closePop: VoidFunction;
}) {
    return (
        <div>
            {[
                OfferStatus.DRAFT,
                OfferStatus.SENT,
                OfferStatus.ACCEPTED,
                OfferStatus.DECLINED,
            ].map(status => {
                const { tag, color } = getStatus(status);
                if (status === OfferStatus.ACCEPTED) {
                    return (
                        <div style={{ padding: 8 }}>
                            <Tag
                                style={{ cursor: "pointer", width: "100%" }}
                                onClick={() => {
                                    setCompleteModal(offerId);
                                    closePop();
                                }}
                                color={color}>
                                {tag}
                            </Tag>
                        </div>
                    );
                }
                return (
                    <div style={{ padding: 8 }}>
                        <Tag
                            style={{ cursor: "pointer", width: "100%" }}
                            onClick={async () => {
                                const data =
                                    status === OfferStatus.DECLINED
                                        ? { status, declineTime: new Date() }
                                        : { status };
                                await updateOffer(offerId, data);
                                closePop();
                            }}
                            color={color}>
                            {tag}
                        </Tag>
                    </div>
                );
            })}
        </div>
    );
}

function getSubPrices(offer: AppOffer["offer"]) {
    const { monthlySubscriptionPrice } = getSubscriptionPrice(offer.m2);
    const { monthly } = getAdditionalServicesSubscriptionFixedPrice(offer);
    const { monthlyData } = getAdditionalServicesSubscriptionVariablePrice(offer);
    const year1 = monthlySubscriptionPrice * year1Discount + monthly + monthlyData;

    const year2 =
        monthlySubscriptionPrice * year2Discount + monthly / (2 * 12) + monthlyData;

    const year3 =
        monthlySubscriptionPrice * year3Discount + monthly / (3 * 12) + monthlyData;

    const discount = offer.discount;

    const addedDiscount = getDiscountFactor(discount || 0);

    return {
        subYear1: year1 * addedDiscount,
        subYear2: year2 * addedDiscount,
        subYear3: year3 * addedDiscount,
    };
}
export function getLiterPrices(offer: AppOffer["offer"]) {
    const { yearly } = getAdditionalServicesSubscriptionFixedPrice(offer);
    const { yearlyData } = getAdditionalServicesSubscriptionVariablePrice(offer);
    const { monthlyLiterPrice } = getLiterPrice(offer.m2);

    const discount = offer.discount;

    const addedDiscount = getDiscountFactor(discount || 0);

    return {
        monthlyLiterPrice: monthlyLiterPrice * addedDiscount,
        fixedPrices: yearly,
        variable: yearlyData,
    };
}

function StatusPicker({
    status,
    setCompleteModal,
    offerId,
}: {
    status: AppOffer["status"];
    setCompleteModal: Dispatch<SetStateAction<string | null>>;
    offerId: string;
}) {
    const { tag, color } = getStatus(status);
    const [open, setopen] = useState(false);
    return (
        <Popover
            open={open}
            onOpenChange={setopen}
            content={
                <PopContent
                    offerId={offerId}
                    setCompleteModal={setCompleteModal}
                    closePop={() => setopen(false)}
                />
            }
            trigger="click">
            <Tag style={{ cursor: "pointer" }} color={color}>
                {tag}
            </Tag>
        </Popover>
    );
}

export const priceFormat = new Intl.NumberFormat("da-DK", {
    style: "currency",
    currency: "DKK",
    maximumFractionDigits: 0,
});

function OfferPrice({ appOffer }: { appOffer: AppOffer }) {
    const isSubscription = appOffer.offer?.priceType === PriceType.SUBSCRIPTION;
    const { subYear1, subYear2, subYear3 } = getSubPrices(appOffer.offer);

    const { fixedPrices, variable, monthlyLiterPrice } = getLiterPrices(appOffer.offer);

    const discountedGranulesPrice =
        appOffer.offer.sweepingGranules *
        sweepingGranulesLiterPrice *
        getDiscountFactor(appOffer.offer.discountGranules || 0);

    const granulesPrice = (
        <div>
            <div>
                Fejegranulat: {priceFormat.format(discountedGranulesPrice / 12)}
                {" / md "}
                (1 års forbrug samlet {priceFormat.format(discountedGranulesPrice)})
            </div>
        </div>
    );

    if (isSubscription) {
        return (
            <>
                <Tooltip
                    title={
                        <div>
                            <div>År 1: {priceFormat.format(subYear1)}/md</div>
                            <div>År 2: {priceFormat.format(subYear2)}/md</div>
                            <div>År 3: {priceFormat.format(subYear3)}/md</div>
                            {appOffer.offer.sweepingGranules && granulesPrice}
                        </div>
                    }>
                    <Tag color="blue-inverse">Abonnement</Tag>
                </Tooltip>
            </>
        );
    }
    return (
        <Tooltip
            title={
                <div>
                    {fixedPrices > 0 && (
                        <div>
                            Sensor m.m. (engangsbeløb): {priceFormat.format(fixedPrices)}
                        </div>
                    )}
                    {variable > 0 && (
                        <div>
                            Sensor data m.m. (årlig): {priceFormat.format(variable)}
                        </div>
                    )}
                    <div>Liter pris: {priceFormat.format(monthlyLiterPrice)} / md</div>
                    {granulesPrice}
                </div>
            }>
            <Tag color="magenta-inverse">Liter pris</Tag>
        </Tooltip>
    );
}

export const literFormat = new Intl.NumberFormat("da-DK", {
    style: "unit",
    unit: "liter",
    maximumFractionDigits: 0,
});

function OfferLiters({ appOffer }: { appOffer: AppOffer }) {
    const liters = getLiters(appOffer.offer.m2);
    const granules = appOffer.offer.sweepingGranules;
    return (
        <>
            <div>
                Estimeret gulvvask forbrug: {literFormat.format(liters.litersPrMonth)} /
                md
            </div>
            {granules > 0 && (
                <div>
                    Estimeret fejegranult forbrug: {literFormat.format(granules / 12)} /
                    md <br />
                    (leveres årligt {granules} liter)
                </div>
            )}
        </>
    );
}

export default function OffersTable({
    offers,
    orgs,
    stats,
    loadingInvoices,
    invoices,
}: {
    offers: AppOffer[];
    orgs: IOrg[];
    stats: Stats;
    loadingInvoices: boolean;
    invoices: Invoice[];
}) {
    const history = useHistory();
    const { state, dispatch } = useOfferContext();
    const drawerCxt = useOfferDrawerContext();
    const { t } = useTranslation();
    const [completeModal, setCompleteModal] = useState<string | null>(null);

    const columns = [
        {
            title: "Virksomhed",
            dataIndex: "title",
            key: "title",
        },
        {
            title: "Kontaktperson",
            dataIndex: "contact",
            key: "contact",
            render: (contact: any) => {
                return contact.att;
            },
        },
        {
            title: "Pris",
            dataIndex: "products.price",
            key: "products.price",
            render: (_: number, offer: AppOffer) => {
                return <OfferPrice appOffer={offer} />;
            },
        },
        {
            title: "Liter",
            dataIndex: "products.liter",
            key: "products.liter",
            render: (_: number, offer: AppOffer) => {
                return <OfferLiters appOffer={offer} />;
            },
        },
        {
            title: "Oprettet",
            dataIndex: "createTime",
            key: "createTime",
            render: (date: Date) => {
                return date ? format(date, "dd/MM/yyyy") : "";
            },
        },
        {
            title: "Opdateret",
            dataIndex: "updateTime",
            key: "updateTime",
            render: (date: Date) => {
                return date ? format(date, "dd/MM/yyyy") : "";
            },
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (status: AppOffer["status"], record: AppOffer) => {
                return (
                    <StatusPicker
                        status={status}
                        setCompleteModal={setCompleteModal}
                        offerId={record.id}
                    />
                );
            },
        },
        {
            title: "",
            key: "action",
            render: (_: AppOffer, record: AppOffer) => {
                return (
                    <div style={{ display: "flex", flexDirection: "row", gap: 8 }}>
                        <OfferPDF appOffer={record} />
                        <div>
                            <Button
                                icon={<EditOutlined />}
                                onClick={() => {
                                    dispatch({
                                        type: "UPDATE_MULTIPLE",
                                        payload: {
                                            ...record.offer,
                                            offerId: record.id,
                                            displayOfferForm: true,
                                            title: record.title,
                                            contactAtt: record.contact.att,
                                        },
                                    });
                                    // history.push(`/agreements/${record.id}`);
                                }}
                            />
                        </div>
                        <Popconfirm
                            title={t("orders.popover_title")}
                            okText={t("orders.popover_confirm")}
                            cancelText={t("orders.popover_cancel")}
                            onConfirm={() => {
                                updateOffer(record.id, { isDeleted: true });
                            }}>
                            <Button danger icon={<DeleteOutlined />} />
                        </Popconfirm>

                        <Button
                            type="primary"
                            icon={<ArrowRightOutlined />}
                            onClick={() =>
                                drawerCxt.dispatch({
                                    type: "SET_OFFER_ID",
                                    payload: {
                                        offerId: record.id,
                                        orgId: record?.orgId || null,
                                    },
                                })
                            }
                        />
                    </div>
                );
            },
        },
    ];
    if (state.displayOfferForm) {
        return null;
    }

    const closeModal = () => setCompleteModal(null);
    return (
        <div style={{ marginTop: 16 }}>
            <div style={{ display: "flex" }}>
                <Stats stats={stats} />
                <AdminAndCharlotte>
                    <div style={{ display: "flex", height: 150, width: 800 }}>
                        {loadingInvoices ? <Loader /> : <SalesBars invoices={invoices} />}
                    </div>
                </AdminAndCharlotte>
            </div>
            {/* <AvoidSvgOverflow
                style={{
                    position: "absolute",
                    width: 200,
                    height: 50,
                    left: 0,
                    right: 0,
                    margin: "auto",
                }}>
                <SummaryChart stats={stats} />
            </AvoidSvgOverflow> */}
            <div style={{ textAlign: "right" }}>
                <Button
                    type="primary"
                    onClick={() => {
                        dispatch({ type: "SET_NEW_OFFER", payload: true });
                    }}>
                    Nyt tilbud
                </Button>
            </div>
            <Table
                columns={columns}
                dataSource={offers}
                rowKey="id"
                pagination={false}
                scroll={{ x: 600 }}
            />
            <Modal
                title="Vælg hvilket firma tilbuddet skal kobles til"
                open={Boolean(completeModal)}
                footer={null}
                onCancel={closeModal}>
                <div style={{ paddingTop: 16 }}>
                    {/* <label>Vælg firma:</label>
                    
                    <div style={{ paddingTop: 16 }} /> */}
                    {completeModal && (
                        <AcceptForm
                            offerId={completeModal}
                            closeModal={closeModal}
                            orgs={orgs}
                        />
                    )}
                </div>
            </Modal>
        </div>
    );
}
