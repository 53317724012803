import React from "react";
import { Layout, Empty } from "antd";
import HeaderBar from "../../Layout/HeaderBar";
import FooterBar from "../../Layout/FooterBar";
import Container from "./Container";

const { Content } = Layout;

function GPSOverview() {
    return (
        <>
            <Layout>
                <HeaderBar />
                <Content
                    style={{
                        margin: "24px 24px 0",
                    }}>
                    <Container />
                </Content>
                <FooterBar />
            </Layout>
        </>
    );
}

export default GPSOverview;
