import React, { createContext, useContext, useReducer, ReactNode } from "react";

export enum OfferStatus {
    DRAFT = "DRAFT",
    SENT = "SENT",
    ACCEPTED = "ACCEPTED",
    DECLINED = "DECLINED",
}

export enum PriceType {
    SUBSCRIPTION = 2,
    LITER = 3,
}
export interface AirCleaner {
    amount: number;
    leasing: number;
    power: number;
    additionalProducts: number;
}

export interface OfferState {
    m2: number;
    sensors: number;
    gps: number;
    tablets: number;
    sweepingGranules: number;
    dustMeasurements: number;
    airCleaner: AirCleaner | null;
    isMonthlyView: boolean;
    priceType: PriceType | null;
    discount: number | null;
    discountGranules: number | null;
    displayOfferForm: boolean;
    note?: string | null;
    /**
     * If no offerId is provided, the calculator will assume it is a new offer
     */
    offerId?: string;
    title: string | null;
    contactAtt: string | null;
}

export type OfferAction =
    | { type: "SET_M2"; payload: number }
    | { type: "SET_SENSORS"; payload: number }
    | { type: "SET_GPS"; payload: number }
    | { type: "SET_TABLETS"; payload: number }
    | { type: "SET_DUST_MEASUREMENTS"; payload: number }
    | { type: "SET_AIR_CLEANER"; payload: AirCleaner | null }
    | { type: "UPDATE_MULTIPLE"; payload: Partial<OfferState> }
    | { type: "SET_PRICE_TYPE"; payload: PriceType | null }
    | { type: "SET_DISCOUNT"; payload: number | null }
    | { type: "SET_DISCOUNT_GRANULES"; payload: number | null }
    | { type: "SET_NOTE"; payload: string }
    | { type: "SET_NEW_OFFER"; payload: boolean }
    | { type: "RESET" }
    | { type: "TOGGLE_VIEW" };

export const initialState: OfferState = {
    m2: 0,
    sensors: 0,
    gps: 0,
    tablets: 0,
    sweepingGranules: 0,
    dustMeasurements: 0,
    airCleaner: null,
    isMonthlyView: true,
    priceType: null,
    discount: null,
    discountGranules: null,
    displayOfferForm: false,
    note: null,
    title: null,
    contactAtt: null,
};

export function offerReducer(state: OfferState, action: OfferAction): OfferState {
    switch (action.type) {
        case "SET_M2":
            return { ...state, m2: action.payload };
        case "SET_SENSORS":
            return { ...state, sensors: action.payload };
        case "SET_GPS":
            return { ...state, gps: action.payload };
        case "SET_TABLETS":
            return { ...state, tablets: action.payload };
        case "SET_DUST_MEASUREMENTS":
            return { ...state, dustMeasurements: action.payload };
        case "SET_AIR_CLEANER":
            return { ...state, airCleaner: action.payload };
        case "UPDATE_MULTIPLE":
            return { ...state, ...action.payload };
        case "RESET":
            return initialState;
        case "SET_PRICE_TYPE":
            return { ...state, priceType: action.payload };
        case "SET_DISCOUNT":
            return { ...state, discount: action.payload };
        case "SET_DISCOUNT_GRANULES":
            return { ...state, discountGranules: action.payload };
        case "SET_NOTE":
            return { ...state, note: action.payload };
        case "SET_NEW_OFFER":
            return { ...state, displayOfferForm: action.payload };
        case "TOGGLE_VIEW":
            return { ...state, isMonthlyView: !state.isMonthlyView };
        default:
            throw new Error("Unknown action type");
    }
}
