import React, { useState } from "react";
import { Modal, Button, Dropdown, Menu } from "antd";
import AddForm from "./AddForm";
import AddFormAqSensor from "./AddFormAqSensor";
import { useIsMobile } from "../../../hooks/useIsMobile";
import AdminOnly from "../../../components/AdminOnly";
import { SensorType } from "../../../general/enums";
import AddFormGsmPlant from "./AddFormGsmPlant";
import AddFormGpsSensor from "./AddFormGpsSensor";
import AddFormGpsSimTrackingSensor from "./AddFormGpsSensorSimTracking";
import { useParams } from "react-router";
import firebase from "firebase/compat/app";
import Message, { Type } from "components/Notification/Message";
import AddFormGpsPlant from "./AddFormGpsPlant";
import { StyleGuide } from "styles/StyleGuide";

async function setupGPSDemoSensor(orgId: string) {
    try {
        if (!orgId) throw Error("setupGPSDemoSensor:No orgId");
        await firebase
            .firestore()
            .collection("orgs")
            .doc(orgId)
            .collection("sensors")
            .add({
                createTime: new Date(),
                identifier: "DF_HIGH_GPS_DEMO",
                location: new firebase.firestore.GeoPoint(55.63755, 12.25355),
                sensorBoundaries: {
                    bottomLeftBoundary: new firebase.firestore.GeoPoint(
                        55.636196,
                        12.251927,
                    ),
                    bottomRightBoundary: new firebase.firestore.GeoPoint(
                        55.636158,
                        12.255082,
                    ),
                    topLeftBoundary: new firebase.firestore.GeoPoint(
                        55.638736,
                        12.251987,
                    ),
                    topRightBoundary: new firebase.firestore.GeoPoint(
                        55.638681,
                        12.255184,
                    ),
                },
                title: "GPS - DEMO 1",
                type: SensorType.GPS_GY_NEO,
            });
        Message({
            key: "addSensorDemo",
            message: `Alt blev tilføjet korrekt`,
        });
    } catch (error) {
        Message({
            key: "addSensorDemo",
            type: Type.ERROR,
            message: `Der skete en fejl`,
            description: `Kunne ikke oprette demo sensor, prøv venligst igen`,
        });
        console.error(error);
    }
}
async function setupDustDemoSensor(orgId: string) {
    try {
        if (!orgId) throw Error("setupDustDemoSensor:No orgId");
        await firebase
            .firestore()
            .collection("orgs")
            .doc(orgId)
            .collection("sensors")
            .add({
                createTime: new Date(),
                deviceId: "HIGH_DUST_DEMO",
                orgId: orgId,
                thresholds: {
                    co2: 1300,
                    decibel: 60,
                    humidity: 60,
                    lux: 1000,
                    pm_10: 350,
                    pm_2_5: 250,
                    temperature: 30,
                },
                title: "AQ Demo",
                type: SensorType.ESP_AQ,
            });
        Message({
            key: "addSensorDemo",
            message: `Alt blev tilføjet korrekt`,
        });
    } catch (error) {
        Message({
            key: "addSensorDemo",
            type: Type.ERROR,
            message: `Der skete en fejl`,
            description: `Kunne ikke oprette demo sensor, prøv venligst igen`,
        });
        console.error(error);
    }
}

function AddCard() {
    const { orgId } = useParams<{ orgId: string }>();
    const [type, settype] = useState<SensorType | null>(null);
    const isMobile = useIsMobile();
    const showModal = (type: SensorType) => settype(type);
    const handleCancel = () => {
        settype(null);
    };

    const renderMenu = () => (
        <Menu>
            <span style={{ color: StyleGuide.palette.grey500 }}>AQ Sensors</span>
            <Menu.Item key="menu-esq-sensor">
                <div role="button" onClick={() => showModal(SensorType.ESP_AQ)}>
                    AQ sensor
                </div>
            </Menu.Item>
            <Menu.Item key="menu-dust-sensor">
                <div role="button" onClick={() => showModal(SensorType.FOOBOT)}>
                    foobot sensor
                </div>
            </Menu.Item>
            <span style={{ color: StyleGuide.palette.grey500 }}>Anlæg Sensors</span>
            <Menu.Item key="menu-gsm-plant">
                <div onClick={() => showModal(SensorType.GSM_PLANT)}>GSM Anlæg</div>
            </Menu.Item>
            <Menu.Item key="menu-gps-plant">
                <div onClick={() => showModal(SensorType.GPS_SIMTRACKING)}>
                    GPS Anlæg (mobilt)
                </div>
            </Menu.Item>

            <span style={{ color: StyleGuide.palette.grey500 }}>GPS Sensors</span>
            <Menu.Item key="menu-gps-sensor-new">
                <div onClick={() => showModal(SensorType.GPS_SIMTRACKING)}>
                    GPS Sensor (ny)
                </div>
            </Menu.Item>
            <Menu.Item key="menu-gps-sensor">
                <div onClick={() => showModal(SensorType.GPS_GY_NEO)}>GPS Sensor</div>
            </Menu.Item>

            <span style={{ color: StyleGuide.palette.grey500 }}>DEMO Sensors</span>
            <Menu.Item key="menu-gps-sensor-demo">
                <div role="button" onClick={() => setupGPSDemoSensor(orgId)}>
                    GPS DEMO
                </div>
            </Menu.Item>
            <Menu.Item key="menu-esq-sensor-demo">
                <div role="button" onClick={() => setupDustDemoSensor(orgId)}>
                    AQ sensor DEMO
                </div>
            </Menu.Item>
        </Menu>
    );
    return (
        <AdminOnly>
            <Dropdown overlay={renderMenu()}>
                <Button size="large" type="primary">
                    Tilføj sensor
                </Button>
            </Dropdown>
            {/* <Button size="large" type="primary" onClick={showModal}>
                Tilføj sensor
            </Button> */}
            {/* <Button size="large" type="primary" onClick={showModal}>
                Tilføj gsm
            </Button> */}
            <Modal
                key={SensorType.ESP_AQ}
                title="Tilføj sensor"
                open={type === SensorType.ESP_AQ}
                // onOk={e => handleOk(e, email)}
                onCancel={handleCancel}
                width={isMobile ? "100%" : "50%"}
                centered
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Cancel
                    </Button>,
                ]}>
                <AddFormAqSensor onClose={handleCancel} />
            </Modal>
            <Modal
                key={SensorType.FOOBOT}
                title="Tilføj sensor"
                open={type === SensorType.FOOBOT}
                // onOk={e => handleOk(e, email)}
                onCancel={handleCancel}
                width={isMobile ? "100%" : "50%"}
                centered
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Cancel
                    </Button>,
                ]}>
                <AddForm onClose={handleCancel} />
            </Modal>
            <Modal
                key={SensorType.GSM_PLANT}
                title="Tilføj sensor"
                open={type === SensorType.GSM_PLANT}
                // onOk={e => handleOk(e, email)}
                onCancel={handleCancel}
                width={isMobile ? "100%" : "50%"}
                centered
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Cancel
                    </Button>,
                ]}>
                <AddFormGsmPlant onClose={handleCancel} />
            </Modal>
            <Modal
                key={SensorType.GPS_GY_NEO}
                title="Tilføj sensor"
                open={type === SensorType.GPS_GY_NEO}
                // onOk={e => handleOk(e, email)}
                onCancel={handleCancel}
                width={isMobile ? "100%" : "50%"}
                centered
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Cancel
                    </Button>,
                ]}>
                <AddFormGpsSensor onClose={handleCancel} />
            </Modal>
            <Modal
                key={SensorType.GPS_SIMTRACKING}
                title="Tilføj sensor"
                open={type === SensorType.GPS_SIMTRACKING}
                // onOk={e => handleOk(e, email)}
                onCancel={handleCancel}
                width={isMobile ? "100%" : "50%"}
                centered
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Cancel
                    </Button>,
                ]}>
                <AddFormGpsSimTrackingSensor onClose={handleCancel} />
            </Modal>
            <Modal
                key={SensorType.GPS_SIMTRACKING}
                title="Tilføj sensor"
                open={type === SensorType.GPS_SIMTRACKING}
                // onOk={e => handleOk(e, email)}
                onCancel={handleCancel}
                width={isMobile ? "100%" : "50%"}
                centered
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Cancel
                    </Button>,
                ]}>
                <AddFormGpsPlant onClose={handleCancel} />
            </Modal>
        </AdminOnly>
    );
}

export default AddCard;
