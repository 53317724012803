import firebase from "firebase/compat/app";
import { useEffect, useState } from "react";

export type IrrelevantGroupCategoryMap = {
    [auditTemplateCategoryId: string]: string[]; // array of subGroup ids
};

export default function useIrrelevantGroupCategoryMap({ groupId }: { groupId: string }) {
    const [irrelevantGroupCategoryMap, setIrrelevantGroupCategoryMap] =
        useState<IrrelevantGroupCategoryMap>({});

    useEffect(() => {
        console.log(groupId);
        let unsubscribe: any = null;
        if (groupId) {
            unsubscribe = firebase
                .firestore()
                .collection(`groups`)
                .doc(groupId)
                .collection("groupCategoryMap")
                .doc("irrelevant")
                .onSnapshot(doc => {
                    if (doc.exists) {
                        const data = doc.data();
                        if (!data) return;
                        setIrrelevantGroupCategoryMap(data as IrrelevantGroupCategoryMap);
                    }
                });
        }
        return () => {
            console.log("unsubscribe");
            unsubscribe?.();
            setIrrelevantGroupCategoryMap({});
        };
    }, [groupId]);
    return {
        irrelevantGroupCategoryMap,
    };
}
