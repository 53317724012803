import React, { useEffect, useState } from "react";
import { functions } from "../../../firebase";

export type Invoice = {
    invoiceNumber: string; //"17990";
    customerAccountNumber: string; //"100359";
    invoiceDate: number; //"2024-10-03T00:00:00";
    paymentDate: number; //"2024-10-17T00:00:00";
    customerRef: string; //"Frederik B. Jensen";
    ownRef: string; //"Charlotte Jensen";
    paidInFull: boolean;
    currencyCode: string; //"DKK";
    contactPersonName: string; //"Frederik B. Jensen.";
    hnInvoiceID: number; // 10769937;
    hnInvoiceSalesID: number; // 2435881;
    netAmount: number; // 4915.0;
    grossAmount: number; // 6143.75;
    vatAmount: number; // 1228.75;
    sendType: string; //"Send som email";
    eanNumber: string; //"";
    email: string; //"bogholderiet@bcco.dk";
    isAdvanceInvoice: boolean;
    allAssociatedAppointmentNumbers: string[]; //["22326"];
    allAssociatedHNAppointmentIDs: number[]; // [2476901];
};

export default function useInvoicesByOrg(customerId?: string) {
    const [loading, setLoading] = useState(false);
    const [invoices, setInvoices] = useState<Invoice[]>([]);
    useEffect(() => {
        const getInvoices = async () => {
            try {
                setLoading(true);
                if (!customerId) {
                    return;
                }
                const functionRef = functions.httpsCallable("getInvoiceByCustomer");
                const response = await functionRef({ customerId });

                const errorCode = response?.data?.httpErrorCode?.status;
                if (errorCode) {
                    console.error({ errorCode });
                    return;
                }

                setInvoices(
                    response.data
                        .map((i: Invoice) => ({
                            ...i,
                            invoiceDate: new Date(i.invoiceDate).getTime(),
                            paymentDate: new Date(i.paymentDate).getTime(),
                        }))
                        .sort((a: Invoice, b: Invoice) => b.invoiceDate - a.invoiceDate),
                );
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        getInvoices();

        return () => {};
    }, [customerId]);
    return { loadingInvoices: loading, invoices };
}
