import { firestore, functions } from "../firebase";
import { useEffect, useState } from "react";

import { RequestState } from "interfaces/request";

export default function usePlants() {
    const [plants, setPlants] = useState<any[]>([]);
    const [plantLoadState, setPlantLoadState] = useState(RequestState.INITIAL);

    const getPlantData = async () => {
        try {
            setPlantLoadState(RequestState.PENDING);
            const functionRef = functions.httpsCallable("getAllPlantOverview");
            const response: any = await functionRef();

            const errorCode = response?.data?.httpErrorCode?.status;
            if (errorCode) {
                console.log({ errorCode });
            }
            setPlants(
                response.data?.sort((a: any, b: any) => a.title.localeCompare(b.title)),
            );
            setPlantLoadState(RequestState.RESOLVED);
        } catch (error) {
            console.error(error);
            setPlantLoadState(RequestState.REJECTED);
        }
    };
    useEffect(() => {
        getPlantData();
    }, []);

    return {
        plants,
        error: plantLoadState === RequestState.REJECTED,
        loading:
            plantLoadState === RequestState.INITIAL ||
            plantLoadState === RequestState.PENDING,
    };
}
