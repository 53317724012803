import { GoogleApiWrapper, Map, Marker } from "google-maps-react";
import React from "react";

interface Props {
    google: any;
    position: { lat: number; lng: number };
}

const LoadingContainer = () => <div>Loading map...</div>;

function SimpleMap(props: Props) {
    return (
        // @ts-ignore
        <Map
            zoomControl={false}
            mapTypeControl={false}
            scaleControl={false}
            streetViewControl={false}
            rotateControl={false}
            fullscreenControl={false}
            google={props.google}
            initialCenter={props.position}
            center={props.position}
            zoom={17}
            mapType="satellite"
            style={{ position: "relative", height: 300, width: "100%" }}>
            <Marker position={props.position} />
        </Map>
    );
}

export default GoogleApiWrapper({
    apiKey: "AIzaSyCWLbp3ljXHZhPKg-bfa70BQCuNPNRFY0U",
    LoadingContainer: LoadingContainer,
    // libraries: ["visualization"], // Load the Visualization library
})(SimpleMap);
