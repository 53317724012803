import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from "recharts";
import { Invoice } from "../Drawer/useInvoices";
import { StyleGuide } from "styles/StyleGuide";
// import { sampleData } from "../../AuditReports/sampleData";

export default function SalesBars({ invoices }: { invoices: Invoice[] }) {
    const data = groupInvoicesByMonth(invoices);
    console.log({ data });

    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                width={500}
                height={300}
                data={data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 30,
                }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" interval={0} textAnchor="end" angle={-15} dy={0} />
                <YAxis />
                <Tooltip />
                {/* <Legend /> */}

                <Bar
                    dataKey="paid"
                    name="Betalt"
                    fill={StyleGuide.palette.statusSuccessDark}
                    stackId="a"
                    // @ts-ignore
                    // activeBar={<Rectangle fill="gold" stroke="purple" />}
                />
                <Bar
                    dataKey="unpaid"
                    name="Afventer betaling"
                    fill={StyleGuide.palette.statusWarningDark}
                    stackId="a"

                    // @ts-ignore
                    // activeBar={<Rectangle fill="gold" stroke="purple" />}
                />
            </BarChart>
        </ResponsiveContainer>
    );
}

const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

type MonthlyInvoiceData = {
    name: string;
    paid: number;
    unpaid: number;
};

function groupInvoicesByMonth(invoices: Invoice[]): MonthlyInvoiceData[] {
    const monthlyData = invoices.reduce((acc, invoice) => {
        const date = new Date(invoice.invoiceDate);
        const year = date.getFullYear();
        const month = date.getMonth();
        const monthKey = `${year}-${month}`; // Unique key for each month

        if (!acc[monthKey]) {
            acc[monthKey] = { paid: 0, unpaid: 0 };
        }

        if (invoice.paidInFull) {
            acc[monthKey].paid += invoice.netAmount;
        } else {
            acc[monthKey].unpaid += invoice.netAmount;
        }

        return acc;
    }, {} as { [key: string]: { paid: number; unpaid: number } });

    // Convert to an array, formatting names and sorting by date
    const result = Object.entries(monthlyData).map(([key, values]) => {
        const [year, month] = key.split("-");
        const monthIndex = parseInt(month, 10);
        return {
            name: `${monthNames[monthIndex]} ${year}`,
            paid: values.paid,
            unpaid: values.unpaid,
        };
    });

    // Sort by year and month in ascending order
    return result
        .sort((a, b) => new Date(a.name).getTime() - new Date(b.name).getTime())
        .map(r => {
            return {
                ...r,
                paid: Math.round(r.paid),
                unpaid: Math.round(r.unpaid),
            };
        });
}
