import React, { useState } from "react";
import { Layout, Row, Col, Button } from "antd";
import SideNav from "../../Layout/SideNav";
import HeaderBar from "../../Layout/HeaderBar";
import FooterBar from "../../Layout/FooterBar";
import SensorWidget from "./SensorWidget";
import IssuesStatsWidget from "./IssuesStatsWidget";
import AuditWithIssues from "./AuditWithIssuesWidget";
import StorageWidget from "./StorageWidget";
import { FeatureIds } from "../../modules/featureControl/featuresConfig";
import BlurOverlayContainer from "../../components/Overlay/BlurOverlayContainer";
import AuditOverdueWidget from "./AuditOverdueWidget";
import AuditOnHoldWidget from "./AuditOnHoldWidget";
import { useSelector } from "react-redux";
import { AppState } from "store";
import AuditCompletedTimeline from "./AuditCompletedTimeline";
import AuditWidgetUpcoming from "./AuditWidgetUpcoming";
import "./dashboard.css";
import PreferenceButton, { TASKS } from "./PreferenceButton";
import IssuesUnresolvedWidget from "./IssuesUnresolvedWidget";
import RapportContainer from "Layout/RapportContainer";
import AdminOnly from "components/AdminOnly";

const { Content } = Layout;

// only for the 4 first tasks cards
function getTaskSize(tasks: string[]) {
    const tasksCards = tasks.filter(task => task !== TASKS.COMPLETED_TASKS);
    if (tasksCards.length === 0) {
        return 6;
    }
    if (tasksCards.length > 3) {
        return 6;
    }
    if (tasksCards.length === 3) {
        return 24;
    }
    if (tasksCards.length === 2) {
        return 12;
    }
    if (tasksCards.length === 1) {
        return 8;
    }
    return 24;
}

export default function DashboardContainer() {
    const features = useSelector((state: AppState) => state.features.featuresMap);
    const [hidden, sethidden] = useState<{ tasks: string[]; issues: string[] }>({
        tasks: [],
        issues: [],
    });

    const taskSize = getTaskSize(hidden.tasks);

    return (
        <>
            <SideNav />
            <Layout>
                <HeaderBar />
                <BlurOverlayContainer featureKey={FeatureIds.DASHBOARD}>
                    <Content style={{ margin: "24px 24px 0" }}>
                        <Row gutter={[16, 16]}>
                            <Col
                                lg={24}
                                md={24}
                                sm={24}
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    gap: 8,
                                }}>
                                <RapportContainer buttonType="default" />
                                <AdminOnly>
                                    <PreferenceButton
                                        hidden={hidden}
                                        sethidden={sethidden}
                                    />
                                </AdminOnly>
                            </Col>
                            {!hidden.tasks.includes(TASKS.PLANNED_TASKS) && (
                                <Col lg={taskSize} md={24} sm={24}>
                                    {/* <SensorWidget /> */}
                                    <AuditWidgetUpcoming />
                                </Col>
                            )}
                            {!hidden.tasks.includes(TASKS.RESOLUTION_TASKS) && (
                                <Col lg={taskSize} md={24} sm={24}>
                                    {/* <SensorWidget /> */}
                                    <AuditWithIssues />
                                </Col>
                            )}
                            {!hidden.tasks.includes(TASKS.ON_HOLD_TASKS) && (
                                <Col lg={taskSize} md={24} sm={24}>
                                    {/* <SensorWidget /> */}
                                    <AuditOnHoldWidget />
                                </Col>
                            )}
                            {!hidden.tasks.includes(TASKS.OVERDUE_TASKS) && (
                                <Col lg={taskSize} md={24} sm={24}>
                                    <AuditOverdueWidget />
                                </Col>
                            )}
                            {!hidden.tasks.includes(TASKS.COMPLETED_TASKS) && (
                                <Col lg={24} md={24} sm={24}>
                                    <AuditCompletedTimeline />
                                </Col>
                            )}

                            <Col lg={12} md={24} sm={24}>
                                <IssuesStatsWidget />
                            </Col>
                            <Col lg={12} md={24} sm={24}>
                                <IssuesUnresolvedWidget />
                            </Col>
                            {/* <Col lg={8} md={24} sm={24}></Col> */}

                            {features?.cleanLogs?.isDisplayed !== false && (
                                <Col lg={12} md={24} sm={24}>
                                    <StorageWidget />
                                </Col>
                            )}
                        </Row>
                    </Content>
                </BlurOverlayContainer>
                <FooterBar />
            </Layout>
        </>
    );
}
