import ReportModal from "components/ReportModal";
import { useAppSelector } from "hooks";

export default function RapportContainer({
    buttonType,
}: {
    buttonType?: "default" | "link";
}) {
    const groupId = "RuXfdQ77eGHqAsQSHK5m";
    const orgs = useAppSelector(state =>
        state.orgs.orgs.filter(org => org.groupId === groupId),
    );
    const rgsOnly = orgs.some(org => org.groupId === groupId);

    if (!rgsOnly) return null;
    return <ReportModal groupId={groupId} orgs={orgs} buttonType={buttonType} />;
}
