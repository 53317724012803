import { Button, Card, Divider, InputNumber, Tooltip } from "antd";
import { Dispatch, SetStateAction } from "react";
import {
    aqSensorDataPrice,
    aqSensorPrice,
    dustMeasurementPrice,
    gpsSensorDataPrice,
    gpsSensorPrice,
    tabletPrice,
} from "../Agreements/Context/offerConfigs";
import { getCurrencyFormat } from "./Offer";
import { getHighlight } from "./Calculator";
import {
    getAdditionalServicesSubscriptionFixedPrice,
    getAdditionalServicesSubscriptionVariablePrice,
    getDiscountFactor,
    getLiterPrice,
    getLiters,
    getPrice,
    useOfferContext,
} from "../Agreements/Context/OfferContext";
import { PriceType } from "../Agreements/Context/offerReducer";

export default function LiterPriceCard({
    setRowHover,
    rowHover,
}: {
    setRowHover: Dispatch<SetStateAction<number | null>>;
    rowHover: number | null;
}) {
    const { state, dispatch } = useOfferContext();
    const isMonthly = state.isMonthlyView;
    const { yearlyLiterPrice, monthlyLiterPrice } = getLiterPrice(state.m2);
    const { litersPrMonth, litersPrYear } = getLiters(state.m2);
    const { yearly, monthly } = getAdditionalServicesSubscriptionFixedPrice(state);
    const { yearlyData, monthlyData } =
        getAdditionalServicesSubscriptionVariablePrice(state);
    const liters = isMonthly ? litersPrMonth : litersPrYear;
    const price = isMonthly ? monthlyLiterPrice : yearlyLiterPrice;
    const oneTimeHardwareCost = yearly;
    const continousHardwareCost = isMonthly ? monthlyData : yearlyData;

    const discount = state.discount;

    const airCleaner = {
        leasing: Number(state.airCleaner?.leasing),
        power: Number(state.airCleaner?.power),
        amount: Number(state.airCleaner?.amount),
        additionalProducts: Number(state.airCleaner?.additionalProducts),
    };
    return (
        <Card
            title={`Liter indkøb - pr. ${isMonthly ? "md" : "år"} - ${getPrice(
                state.m2,
            )} kr. pr. liter`}>
            <table className="price-table">
                <thead>
                    <th></th>

                    <th style={{ textAlign: "right" }}>
                        {oneTimeHardwareCost || continousHardwareCost
                            ? "Sensorer/hardware"
                            : null}
                    </th>

                    <th style={{ textAlign: "right" }}>Liter</th>
                    <th style={{ textAlign: "right" }}>Pris</th>
                </thead>
                <tr
                    key={PriceType.LITER}
                    onMouseEnter={() => setRowHover(PriceType.LITER)}
                    onMouseLeave={() => setRowHover(null)}
                    style={getHighlight(state.priceType, rowHover, PriceType.LITER)}>
                    <td style={{ width: "100%" }}>
                        Liter støvbinder:{" "}
                        {
                            // (3-5 gange - 60-80% reduktion):
                        }
                    </td>
                    <td
                        style={{
                            paddingLeft: 24,
                            whiteSpace: "nowrap",
                            textAlign: "right",
                        }}>
                        <HardwareCostCell
                            values={state}
                            oneTimeHardwareCost={oneTimeHardwareCost}
                            continousHardwareCost={continousHardwareCost}
                            isMonthly={isMonthly}
                        />
                    </td>
                    <td
                        style={{
                            paddingLeft: 24,
                            whiteSpace: "nowrap",
                            textAlign: "right",
                        }}>
                        {new Intl.NumberFormat("da-DK", {
                            style: "unit",
                            unit: "liter",
                            maximumFractionDigits: 0,
                        }).format(liters)}
                    </td>
                    <td
                        style={{
                            paddingLeft: 24,
                            whiteSpace: "nowrap",
                            textAlign: "right",
                        }}>
                        {getCurrencyFormat(price)}
                    </td>
                    <td style={{ paddingLeft: 24, textAlign: "right" }}>
                        <Button
                            onClick={() => {
                                dispatch({
                                    type: "SET_PRICE_TYPE",
                                    payload: PriceType.LITER,
                                });
                            }}>
                            Vælg
                        </Button>
                    </td>
                </tr>
                {state.priceType && (
                    <>
                        <tr>
                            <td colSpan={8}>
                                <Divider style={{ margin: "8px 0" }} />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={3}>Støvbinder rabat til kunder over 50.000m2</td>
                            {oneTimeHardwareCost && continousHardwareCost ? (
                                <td
                                    colSpan={1}
                                    style={{
                                        textAlign: "right",
                                        fontWeight: 700,
                                        textDecoration: "underline",
                                    }}></td>
                            ) : null}
                            <td colSpan={2}>
                                <InputNumber<number>
                                    value={discount}
                                    min={0}
                                    max={4}
                                    formatter={value => `${value}%`}
                                    parser={value =>
                                        value?.replace("%", "") as unknown as number
                                    }
                                    onChange={value => {
                                        dispatch({
                                            type: "SET_DISCOUNT",
                                            payload: value,
                                        });
                                    }}
                                />
                            </td>
                        </tr>
                        {discount !== null ? (
                            <>
                                <tr>
                                    <td colSpan={8}>
                                        <Divider style={{ margin: "8px 0" }} />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={1}>Pris med {discount}% rabat:</td>
                                    <td
                                        colSpan={1}
                                        style={{
                                            textAlign: "right",
                                            fontWeight: 700,
                                            textDecoration: "underline",
                                        }}></td>
                                    {oneTimeHardwareCost && continousHardwareCost ? (
                                        <td
                                            colSpan={1}
                                            style={{
                                                textAlign: "right",
                                                fontWeight: 700,
                                                textDecoration: "underline",
                                            }}></td>
                                    ) : (
                                        <td colSpan={1}></td>
                                    )}
                                    <td
                                        colSpan={1}
                                        style={{
                                            textAlign: "right",
                                            fontWeight: 700,
                                            textDecoration: "underline",
                                        }}>
                                        {getCurrencyFormat(
                                            price * getDiscountFactor(discount),
                                        )}
                                    </td>
                                    <td
                                        colSpan={1}
                                        style={{
                                            textAlign: "right",
                                            fontWeight: 700,
                                            textDecoration: "underline",
                                        }}></td>
                                    <td colSpan={1}></td>
                                </tr>
                            </>
                        ) : null}
                    </>
                )}
            </table>
            {state.airCleaner && (
                <>
                    <Divider style={{ margin: "16px 0" }} />
                    <h4>Luftrenser omkostninger</h4>

                    {/* <Card title="Besparelser - aks2tal vs luftrensere"> */}
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}>
                        <span>Luftrenser leasing:</span>
                        <span>
                            {getCurrencyFormat(
                                airCleaner.leasing *
                                    airCleaner.amount *
                                    (isMonthly ? 1 : 12),
                            )}
                        </span>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}>
                        <span>El </span>
                        <span>
                            {getCurrencyFormat(
                                airCleaner.power *
                                    airCleaner.amount *
                                    (isMonthly ? 1 : 12),
                            )}
                        </span>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}>
                        <span>Vaskeprodukter der bruges i dag: </span>
                        <span>
                            {getCurrencyFormat(
                                airCleaner.additionalProducts * (isMonthly ? 1 : 12),
                            )}
                        </span>
                    </div>
                    <Divider style={{ margin: "16px 0" }} />
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}>
                        <span>Udgifter i alt pr {isMonthly ? "md" : "år"}:</span>
                        <span>
                            {getCurrencyFormat(
                                (airCleaner.leasing * airCleaner.amount +
                                    airCleaner.power * airCleaner.amount +
                                    airCleaner.additionalProducts) *
                                    (isMonthly ? 1 : 12),
                            )}
                        </span>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}>
                        <span>aks2tal pr {isMonthly ? "md" : "år"}:</span>
                        <span>{getCurrencyFormat(price)}</span>
                    </div>
                    <Divider style={{ margin: "16px 0" }} />
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            fontWeight: 700,
                        }}>
                        <span>Besparelse pr {isMonthly ? "md" : "år"}:</span>
                        <span>
                            {getCurrencyFormat(
                                (airCleaner.leasing * airCleaner.amount +
                                    airCleaner.power * airCleaner.amount +
                                    airCleaner.additionalProducts) *
                                    (isMonthly ? 1 : 12) -
                                    price,
                            )}
                        </span>
                    </div>
                    {/* </Card> */}
                </>
            )}
        </Card>
    );
}

function HardwareCostCell({
    values,
    oneTimeHardwareCost,
    continousHardwareCost,
    isMonthly,
}: {
    values: any;
    oneTimeHardwareCost: number;
    continousHardwareCost: number;
    isMonthly: boolean;
}) {
    if (!oneTimeHardwareCost && !continousHardwareCost) return null;
    console.log({
        gps: values.gps,
    });

    return (
        <>
            <Tooltip
                title={
                    <div>
                        <div>Engangsbeløb:</div>
                        {Number(values.gps) ? (
                            <div>
                                GPS: {getCurrencyFormat(values.gps * gpsSensorPrice)}
                            </div>
                        ) : null}
                        {Number(values.sensors) ? (
                            <div>
                                Sensor:{" "}
                                {getCurrencyFormat(values.sensors * aqSensorPrice)}
                            </div>
                        ) : null}
                        {Number(values.tablets) ? (
                            <div>
                                Tablet: {getCurrencyFormat(values.tablets * tabletPrice)}
                            </div>
                        ) : null}
                        {Number(values.dustMeasurements) ? (
                            <div>
                                Certificeret støvmåling:{" "}
                                {getCurrencyFormat(
                                    values.dustMeasurements * dustMeasurementPrice,
                                )}
                            </div>
                        ) : null}
                    </div>
                }>
                {getCurrencyFormat(oneTimeHardwareCost)}
            </Tooltip>
            {"* "}+{" "}
            <Tooltip
                title={
                    <div>
                        {Number(values.gps) ? (
                            <div>
                                GPS data:{" "}
                                {getCurrencyFormat(values.gps * gpsSensorDataPrice)}{" "}
                                {isMonthly ? "pr. md" : " pr. år"}
                            </div>
                        ) : null}
                        {Number(values.sensors) ? (
                            <div>
                                Sensor data:{" "}
                                {getCurrencyFormat(values.sensors * aqSensorDataPrice)}{" "}
                                {isMonthly ? "pr. md" : " pr. år"}
                            </div>
                        ) : null}
                    </div>
                }>
                {getCurrencyFormat(continousHardwareCost)}*
            </Tooltip>
        </>
    );
}
