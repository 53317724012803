import { Button, Card } from "antd";
import { PlantStatus } from "./PlantStatus";
import { StyleGuide } from "styles/StyleGuide";
import { AlertOutlined } from "@ant-design/icons";
import { format } from "date-fns";
import { useState } from "react";
import SimpleMap from "components/GoogleMap/Simple";
import { Map, Marker } from "google-maps-react";

export function MobilePlantCard({
    plant,
    lastSeen,
    org,
}: {
    plant: any;
    lastSeen: number | null;
    org: any;
}) {
    return (
        <Card>
            <div style={{ display: "flex", alignItems: "center" }}>
                <div
                    style={{
                        position: "absolute",
                        display: "flex",
                        top: 16,
                        left: 16,
                        alignItems: "center",
                        gap: 8,
                    }}>
                    <PlantStatus lastSeen={lastSeen} />{" "}
                    <span
                        style={{
                            fontSize: 12,
                            color: StyleGuide.palette.grey700,
                        }}>
                        Drift status
                    </span>
                </div>
                <img
                    style={{ width: 100 }}
                    src={require("../../assets/images/icons/gps-plant.png")}
                />
                <div style={{ wordBreak: "break-word" }}>
                    <div>
                        <span>{org.title} /</span>{" "}
                        <span style={{ fontWeight: 700 }}>{plant.title}</span>
                    </div>
                    <div>
                        Sidst set:{" "}
                        {lastSeen ? format(lastSeen, "dd/MM/yyyy HH:mm") : "N/A"}
                    </div>
                </div>
            </div>
            {plant.latestData && <PlantData data={plant.latestData} />}
        </Card>
    );
}

function PlantData({ data }: any) {
    const [open, setOpen] = useState(false);
    console.log({ data });
    const position = { logTime: new Date(), lat: data.lat, lng: data.lng };
    return (
        <div>
            <div>
                <Button block type="default" onClick={() => setOpen(!open)}>
                    {!open ? "Se seneste lokation" : "Skjul seneste lokation"}
                </Button>
            </div>
            {open && (
                <div style={{ position: "relative", height: 300 }}>
                    <SimpleMap position={position} />
                </div>
            )}
        </div>
    );
}
