// addtional service prices
export const aqSensorPrice = 4750;
export const aqSensorDataPrice = 1440;
export const gpsSensorPrice = 3500;
export const gpsSensorDataPrice = 1440;
export const dustMeasurementPrice = 16000;
export const tabletPrice = 2500;

export const krPrM2Small = 0.6; // smaller than 30000
export const krPrM2Large = 0.4; // greater than 30000

// separator of small and large m2
export const limitM2 = 30000;
// product coverage m2 pr liter
export const coverageM2PrLiter = 1000;
export const coverageM2PrLiterSmall = 700;
// days
export const workingDaysPrYear = 260;
export const weeksPrYear = 52;
// product price
export const pricePrLiterSmall = 109; // smaller than 30000
export const pricePrLiterLarge = 109; // greater than 30000
// subcription discount
export const year1Discount = 1.05;
export const year2Discount = 1;
export const year3Discount = 0.95;
// dust measurement result

// sweeping granules
export const sweepingGranulesLiterPrice = 15;
