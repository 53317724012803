import { AuditGuide } from "modules/auditTemplates/types";
import { post } from "../../general/api";
import { AuditSchedule, AuditSourceType } from "./types";
// import { RecurringOptions } from "../../../functions/src/enums";

interface IParams {
    // templateTitle: string;
    // templateNote: string;
    taskTitle?: string; // IF QUICK TASK
    groupId?: string; // used if it is a group audit template
    orgId: string;
    dueDate: Date;
    templateId: string;
    recurringOption: any; // RecurringOptions;
    tasks: any;
    startDaysBeforeDueDate?: number;
    notifyDaysAfterDueDate?: number;
    responsible: AuditSchedule["responsible"];
    auditSourceType?: AuditSourceType;
    createBy: { id: string; name: string };
    // guide: AuditGuide | null;
    // auditTemplateCategory: string | null;
}

export async function addAuditSchedules({
    // templateTitle,
    // templateNote,
    taskTitle, // IF QUICK TASK
    groupId,
    orgId,
    dueDate,
    templateId,
    recurringOption,
    tasks,
    startDaysBeforeDueDate,
    notifyDaysAfterDueDate,
    responsible,
    auditSourceType,
    createBy,
}: // guide,
// auditTemplateCategory,
IParams) {
    try {
        const response = await post("recurringAudits", {
            // templateTitle,
            // templateNote,
            taskTitle,
            groupId,
            orgId,
            dueDate,
            templateId,
            recurringOption,
            tasks,
            startDaysBeforeDueDate,
            notifyDaysAfterDueDate,
            responsible,
            auditSourceType,
            createBy,
            // guide,
            // auditTemplateCategory,
        });
        if (response.status !== 200) {
            throw new Error(`${response.status}`);
        }
    } catch (error) {
        throw new Error(error as string);
    }
}
