import { Button, Card, Tooltip } from "antd";
import { StyleGuide } from "styles/StyleGuide";
import { format } from "date-fns";
import { useState } from "react";
import SimpleMap from "components/GoogleMap/Simple";
import { GPSStatus } from "./GPSStatus";
import { SensorType } from "general/enums";

export function MobileGPSCard({
    gps,
    lastSeen,
    org,
    orgId,
}: {
    gps: any;
    lastSeen: number | null;
    org: any;
    orgId: string;
}) {
    const getIcon = () => {
        if (gps.type === SensorType.GPS_SIMTRACKING) {
            return require("../../assets/images/icons/gps-simtracking.png");
        }
        if (gps.type === SensorType.GPS_GY_NEO) {
            return require("../../assets/images/icons/gps-icon.png");
        }
        return require("../../assets/images/icons/gps-icon.png");
    };
    const iconPath = getIcon();
    return (
        <Card>
            <div style={{ display: "flex", alignItems: "center" }}>
                <div
                    style={{
                        position: "absolute",
                        display: "flex",
                        top: 16,
                        left: 16,
                        alignItems: "center",
                        gap: 8,
                    }}>
                    <GPSStatus lastSeen={lastSeen} />{" "}
                    <span
                        style={{
                            fontSize: 12,
                            color: StyleGuide.palette.grey700,
                        }}>
                        Drift status
                    </span>
                </div>
                <img style={{ width: 100 }} src={iconPath} />
                <Tooltip
                    title={
                        <div>
                            <div style={{ fontWeight: 700 }}>id: {gps.identifier}</div>
                            <div style={{ fontWeight: 700 }}>type: {gps.type}</div>
                        </div>
                    }>
                    <div style={{ wordBreak: "break-word" }}>
                        <div>
                            <span>{org?.title || orgId} /</span>{" "}
                            <span style={{ fontWeight: 700 }}>{gps.title}</span>
                        </div>
                        <div>
                            Sidst set:{" "}
                            {lastSeen ? format(lastSeen, "dd/MM/yyyy HH:mm") : "N/A"}
                        </div>
                    </div>
                </Tooltip>
            </div>
            {gps.latestData && <PlantData data={gps.latestData} />}
        </Card>
    );
}

function PlantData({ data }: any) {
    const [open, setOpen] = useState(false);
    console.log({ data });
    const position = { logTime: new Date(), lat: data.lat, lng: data.lng };
    return (
        <div>
            <div>
                <Button block type="default" onClick={() => setOpen(!open)}>
                    {!open ? "Se seneste lokation" : "Skjul seneste lokation"}
                </Button>
            </div>
            {open && (
                <div style={{ position: "relative", height: 300 }}>
                    <SimpleMap position={position} />
                </div>
            )}
        </div>
    );
}
