import { useEffect, useState } from "react";
import { Alert, Button, Flex, Space } from "antd";
import { addMonths, differenceInDays } from "date-fns";
import firebase from "firebase/compat/app";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { DBAuditSchedule } from "modules/auditSchedule/types";
import { useTranslation } from "react-i18next";
import confirmModal from "general/confirmModal";
import Message, { Type } from "components/Notification/Message";
import { addAuditSchedule } from "modules/auditSchedule/actions";
import { getScheduleByEnum } from "./scheduleHelpers";
import { AuditSourceType } from "../../../functions/src/enums";

export default function AuditsExpire() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [runningOut, setrunningOut] = useState<(DBAuditSchedule & { id: string })[]>(
        [],
    );
    const addRequestState = useAppSelector(
        state => state.auditSchedules?.addRequestState,
    );
    const user = useAppSelector(state => state.user.details);
    const orgId = useAppSelector(state => state.orgs.currentOrg.id);
    const intl = useAppSelector(state => state.user.userDoc.intl);
    useEffect(() => {
        let unsubscribe: any = null;
        if (orgId) {
            unsubscribe = firebase
                .firestore()
                .collection(`orgs/${orgId}/auditSchedules`)
                .where("lastRecurring", "==", true)
                .where("dueDate", "<", addMonths(new Date(), 1))
                .onSnapshot(query => {
                    if (!query.empty) {
                        const list = query.docs.map(doc => ({
                            id: doc.id,
                            ...(doc.data() as DBAuditSchedule),
                        }));
                        setrunningOut(list);
                        console.log({ runningout: list });
                    } else {
                        setrunningOut([]);
                    }
                });
        }
        return () => unsubscribe?.();
    }, [orgId]);

    const extendTask = async (audit: DBAuditSchedule & { id: string }) => {
        const payload = {
            orgId,
            groupId: audit?.groupId ?? null,
            data: {
                // templateTitle: audit.templateTitle,
                // templateNote: audit.templateNote,
                dueDate: audit.dueDate.toDate(),
                // guide: audit.guide,
                templateId: audit.templateId,
                recurringOption: audit.recurringOption, //RecurringOptions,
                startDaysBeforeDueDate: audit.startDaysBeforeDueDate,
                notifyDaysAfterDueDate: audit.notifyDateAfterDueDate
                    ? // calculate days between notify and due date
                      differenceInDays(
                          audit.notifyDateAfterDueDate.toDate(),
                          audit.dueDate.toDate(),
                      )
                    : undefined,
                auditSourceType: audit.auditSourceType,
                createBy: { id: user.uid, name: user.name || user.email },
                // auditTemplateCategory: audit.auditTemplateCategory || null,
            },
            auditId: audit.id,
        };
        console.log({ payload });
        // @ts-ignore
        dispatch(addAuditSchedule(payload));
    };

    const expireTask = async (id: string) => {
        const messageKey = "expireTask";
        try {
            if (!id || !orgId) throw new Error("Missing id or orgId");
            Message({
                key: messageKey,
                type: Type.LOADING,
                message: `Loading...`,
            });
            await firebase
                .firestore()
                .collection(`orgs/${orgId}/auditSchedules`)
                .doc(id)
                .set({ lastRecurring: false }, { merge: true });
            Message({
                key: messageKey,
                type: Type.SUCCESS,
                message: `Opgaven er nu opdateret`,
            });
        } catch (error) {
            console.error(error);
            Message({
                key: messageKey,
                type: Type.ERROR,
                message: `Der skete en fejl`,
                description: `Prøv venligst igen`,
            });
        }
    };
    const confirmExpireTask = (id: string) => {
        confirmModal({
            title: "Er du sikker på du vil lade opgaven udløbe?",
            onOk: () => {
                expireTask(id);
            },
        });
    };

    if (runningOut.length < 1) return null;

    return (
        <Alert
            message="Planlagte opgaver udløber snart, forlæng eller lad dem udløbe."
            description={runningOut.map(audit => {
                const dateString = getScheduleByEnum(
                    audit.recurringOption,
                    audit.dueDate.toDate(),
                    intl,
                );
                return (
                    <Flex
                        justify="space-between"
                        // style={boxStyle} justify={justify} align={alignItems}
                    >
                        <Flex align="center">
                            <b>{audit.templateTitle}</b> - {t("Udløber")}:{" "}
                            {t("general.dateTime", { date: audit.dueDate.toDate() })} -
                            (gentages: {dateString})
                        </Flex>
                        <Space>
                            <Button
                                type="primary"
                                loading={addRequestState?.[audit.id]}
                                onClick={() => extendTask(audit)}>
                                Forlæng
                            </Button>
                            <Button
                                danger
                                ghost
                                onClick={() => confirmExpireTask(audit.id)}>
                                Lad udløbe
                            </Button>
                        </Space>
                    </Flex>
                );
            })}
            type="info"
            // TODO: Add action buttons
            // action={
            //     <Space>
            //         <Button type="primary">Forlæng alle</Button>
            //         <Button danger ghost>
            //             Lad alle udløbe
            //         </Button>
            //     </Space>
            // }
        />
    );
}
