import React, { useState } from "react";
import { Layout, Space, Tag, Tooltip } from "antd";
import HeaderBar from "../../Layout/HeaderBar";
import { Content } from "antd/lib/layout/layout";
import FooterBar from "../../Layout/FooterBar";
import {
    getAdditionalServicesSubscriptionFixedPrice,
    getAdditionalServicesSubscriptionVariablePrice,
    getDiscountFactor,
    getLiters,
    getSubscriptionPrice,
    OfferProvider,
} from "./Context/OfferContext";
import useAppOffers, { AppOffer } from "hooks/useAppOffers";
import OffersTable, {
    getLiterPrices,
    literFormat,
    priceFormat,
} from "./Offers/OffersTable";
import { useAppSelector } from "hooks";
import {
    sweepingGranulesLiterPrice,
    year1Discount,
    year2Discount,
    year3Discount,
} from "./Context/offerConfigs";
import { OfferStatus, PriceType } from "./Context/offerReducer";
import { StyleGuide } from "styles/StyleGuide";
import Calculator from "Views/AgreementDetail/Calculator";
import DrawerOffer from "./Drawer";
import { OfferDrawerProvider } from "./ContextDrawer/OfferContext";
import useInvoicesCharlotte from "./Drawer/useInvoicesCharlotte";

function computeSubscriptionPrice(offer: AppOffer["offer"]) {
    const { monthlySubscriptionPrice } = getSubscriptionPrice(offer.m2);
    const { monthly } = getAdditionalServicesSubscriptionFixedPrice(offer);
    const { monthlyData } = getAdditionalServicesSubscriptionVariablePrice(offer);
    const year1 = monthlySubscriptionPrice * year1Discount + monthly + monthlyData;

    const year2 =
        monthlySubscriptionPrice * year2Discount + monthly / (2 * 12) + monthlyData;

    const year3 =
        monthlySubscriptionPrice * year3Discount + monthly / (3 * 12) + monthlyData;

    const discount = offer.discount;

    const addedDiscount = getDiscountFactor(discount || 0);

    return ((year1 + year2 + year3) / 3) * addedDiscount;
}

export type Stats = {
    draft: { sales: number; fixed: number; liters: number; litersGranules: number };
    sent: { sales: number; fixed: number; liters: number; litersGranules: number };
    accepted: { sales: number; fixed: number; liters: number; litersGranules: number };
    declined: { sales: number; fixed: number; liters: number; litersGranules: number };
};

function computeStats(offers: AppOffer[]) {
    let stats: Stats = {
        draft: { sales: 0, fixed: 0, liters: 0, litersGranules: 0 },
        sent: { sales: 0, fixed: 0, liters: 0, litersGranules: 0 },
        accepted: { sales: 0, fixed: 0, liters: 0, litersGranules: 0 },
        declined: { sales: 0, fixed: 0, liters: 0, litersGranules: 0 },
    };
    offers.forEach(offer => {
        const subscriptionPrice = computeSubscriptionPrice(offer.offer);
        const liters = getLiters(offer.offer.m2);
        const { monthlyLiterPrice, fixedPrices, variable } = getLiterPrices(offer.offer);
        const monthlyGranulesPrice =
            (offer.offer.sweepingGranules *
                sweepingGranulesLiterPrice *
                getDiscountFactor(offer.offer.discountGranules || 0)) /
            12;
        const monthlyGranulesLiter = offer.offer.sweepingGranules / 12;
        const isSubscription = offer.offer.priceType === PriceType.SUBSCRIPTION;
        if (offer.status === OfferStatus.DRAFT) {
            stats.draft.sales +=
                (isSubscription ? subscriptionPrice : monthlyLiterPrice + variable) +
                monthlyGranulesPrice;
            stats.draft.liters += liters.litersPrMonth;
            stats.draft.litersGranules += monthlyGranulesLiter;
            stats.draft.fixed += fixedPrices;
        }
        if (offer.status === OfferStatus.SENT) {
            stats.sent.sales +=
                (isSubscription ? subscriptionPrice : monthlyLiterPrice + variable) +
                monthlyGranulesPrice;
            stats.sent.liters += liters.litersPrMonth;
            stats.sent.litersGranules += monthlyGranulesLiter;
            stats.sent.fixed += fixedPrices;
        }
        if (offer.status === OfferStatus.ACCEPTED) {
            stats.accepted.sales +=
                (isSubscription ? subscriptionPrice : monthlyLiterPrice + variable) +
                monthlyGranulesPrice;
            stats.accepted.liters += liters.litersPrMonth;
            stats.accepted.litersGranules += monthlyGranulesLiter;
            stats.accepted.fixed += fixedPrices;
        }
        if (offer.status === OfferStatus.DECLINED) {
            stats.declined.sales +=
                (isSubscription ? subscriptionPrice : monthlyLiterPrice + variable) +
                monthlyGranulesPrice;
            stats.declined.liters += liters.litersPrMonth;
            stats.declined.litersGranules += monthlyGranulesLiter;
            stats.declined.fixed += fixedPrices;
        }
    });
    return stats;
}

export default function Agreements() {
    const { offers } = useAppOffers();
    const { loadingInvoices, invoices } = useInvoicesCharlotte();
    const orgs = useAppSelector(state => state.orgs.orgs);
    const stats = computeStats(offers);
    return (
        <>
            {/* <SideNav /> */}
            <OfferDrawerProvider>
                <OfferProvider>
                    <Layout>
                        <HeaderBar />
                        <Content style={{ margin: "24px 24px 0" }}>
                            <Space />

                            <Space />
                            <OffersTable
                                offers={offers}
                                orgs={orgs}
                                stats={stats}
                                invoices={invoices}
                                loadingInvoices={loadingInvoices}
                            />
                            <Calculator />
                            <DrawerOffer offers={offers} />
                            {/* <ModalBilling appProducts={appProducts} /> */}
                        </Content>
                        <FooterBar />
                    </Layout>
                </OfferProvider>
            </OfferDrawerProvider>
        </>
    );
}

export function Stats({ stats }: { stats: Stats }) {
    const accumulatedFixed = stats.draft.fixed + stats.sent.fixed;
    return (
        <div style={{ display: "flex", gap: 16 }}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <Tooltip title="Kladder og sendte tilbud lagt sammen">
                    <Tag color={StyleGuide.palette.green}>Potentiale</Tag>
                </Tooltip>
                <div>
                    <div>
                        {priceFormat.format(stats.draft.sales + stats.sent.sales)} / md{" "}
                        {accumulatedFixed > 0 && (
                            <Tooltip
                                title={
                                    <div>
                                        <div>Engangsbeløb:</div>
                                        <div>
                                            Dækker over sensorer + gps + støvmålinger
                                        </div>
                                    </div>
                                }>
                                + {priceFormat.format(accumulatedFixed)}
                            </Tooltip>
                        )}
                    </div>
                    <div style={{ color: StyleGuide.palette.grey600 }}>
                        <Tooltip title="Støvbinder gulvvask">
                            {literFormat.format(stats.draft.liters + stats.sent.liters)} /
                            md{" - "}
                        </Tooltip>
                        <Tooltip title="Støvbinder fejegranulat">
                            {literFormat.format(
                                stats.draft.litersGranules + stats.sent.litersGranules,
                            )}{" "}
                            / md{" "}
                        </Tooltip>
                    </div>
                </div>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
                <div>
                    <Tag color={StyleGuide.palette.success}>Accepteret</Tag>
                </div>
                <div>
                    <div>
                        {priceFormat.format(stats.accepted.sales)} / md{" "}
                        {stats.accepted.fixed > 0 && (
                            <Tooltip
                                title={
                                    <div>
                                        <div>Engangsbeløb:</div>
                                        <div>
                                            Dækker over sensorer + gps + støvmålinger
                                        </div>
                                    </div>
                                }>
                                + {priceFormat.format(stats.accepted.fixed)}
                            </Tooltip>
                        )}
                        <div style={{ color: StyleGuide.palette.grey600 }}>
                            <Tooltip title="Støvbinder gulvvask">
                                {literFormat.format(stats.accepted.liters)} / md{" - "}
                            </Tooltip>
                            <Tooltip title="Støvbinder fejegranulat">
                                {literFormat.format(stats.accepted.litersGranules)} / md{" "}
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
