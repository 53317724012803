import React, { useState } from "react";
import uuidV4 from "uuid/v4";
import { Upload, Button, Input, Form, message, Select } from "antd";
import firebase from "../../firebase";
import { Redirect } from "react-router";
import { UploadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { UploadListType } from "antd/es/upload/interface";
import { CategoriesState, Category } from "modules/categories/types";
const FormItem = Form.Item;
const { TextArea } = Input;
const { Option } = Select;

function evaluateEnabled({
    description,
    fileList,
}: {
    description: string;
    fileList: any[];
}) {
    if (description && fileList.length === 0) {
        return true;
    }
    if (fileList.length > 0) {
        return true;
    }
    return false;
}

interface Props {
    orgId: string;
    categories: Category[];
    createBy: { id: string; name: string };
}

function CreateIssue(props: Props) {
    const { t } = useTranslation();
    const [state, setState] = useState({
        fileList: [],
        uploading: false,
        image: null,
        description: "",
        filePath: null,
        tags: [] as string[],
        success: false,
    });

    const handleUpload = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        const { fileList } = state;
        // if no files uploaded.
        if (fileList.length < 1) {
            try {
                const issue = {
                    description: state.description,
                    createTime: new Date(),
                    tags: state.tags,
                    displayTags: props.categories
                        .filter(obj => state.tags?.includes(obj.id))
                        .map(obj => obj.title),
                    files: [],
                    isCompleted: false,
                    createBy: props.createBy,
                };

                await firebase
                    .firestore()
                    .collection(`orgs/${props.orgId}/issues`)
                    .add(issue);
                setState({
                    ...state,
                    uploading: false,
                });
                message.success(t("Fejl rapport modtaget"));
                setTimeout(() => {
                    setState({
                        ...state,
                        success: true,
                    });
                }, 500);
            } catch (error) {
                console.error(error);
            }
        } else {
            const formData = new FormData();
            fileList.forEach((file: any) => {
                formData.append("files[]", file);
            });

            setState({
                ...state,
                uploading: true,
            });

            const issueId = uuidV4();
            firebase
                .storage()
                .ref("orgs/" + props.orgId + "/issues")
                // @ts-ignore
                .putFiles(fileList, props.orgId, issueId)
                .then(async (metadatas: any) => {
                    // Get an array of file metadata
                    const issue = {
                        description: state.description,
                        createTime: new Date(),
                        tags: state.tags,
                        displayTags: props.categories
                            .filter(obj => state.tags?.includes(obj.id))
                            .map(obj => obj.title),
                        files: metadatas.map((file: any) => file.metadata.name),
                        isCompleted: false,
                    };

                    await firebase
                        .firestore()
                        .collection(`orgs/${props.orgId}/issues`)
                        .doc(issueId)
                        .set(issue);
                    setState({
                        ...state,
                        uploading: false,
                    });
                    message.success(t("Fejl rapport modtaget"));
                    setTimeout(() => {
                        setState({
                            ...state,
                            success: true,
                        });
                    }, 500);
                    // http://localhost:3000/orgs/BcCBFd1E6q3sll1TROjo/issues
                })
                .catch(function (error: Error) {
                    console.error(error);
                })
                .finally(() => {
                    setState({
                        ...state,
                        uploading: false,
                    });
                });
        }
    };

    const handleTagChange = (value: any) => {
        setState({ ...state, tags: value });
    };

    const { uploading, success } = state;
    if (success) {
        return <Redirect to={`/orgs/${props.orgId}/issues`} />;
    }

    const properties = {
        multiple: true,
        name: "avatar",
        listType: "picture-card" as UploadListType,
        style: { width: "100%" },
        onRemove: (file: any) => {
            setState(({ fileList }: any) => {
                const index = fileList.indexOf(file);
                const newFileList = fileList.slice();
                newFileList.splice(index, 1);
                return {
                    ...state,
                    fileList: newFileList,
                };
            });
        },
        onPreview: (file: any) => {
            console.log(file);
        },
        beforeUpload: (file: any) => {
            // @ts-ignore
            setState(({ fileList }: any) => {
                return {
                    ...state,
                    fileList: [...fileList, file],
                };
            });
            return false;
        },
        defaultFileList: state.fileList,
    };

    const isEnabled = evaluateEnabled({
        description: state.description,
        fileList: state.fileList,
    });

    return (
        <div>
            <Form layout="vertical">
                <div style={{ width: "100%" }}>
                    <Upload {...properties} className="avatar-uploader">
                        <Button style={{ width: "100%" }}>
                            <UploadOutlined /> {t("issues.new.uploadfileplaceholder")}
                        </Button>
                    </Upload>
                </div>
                <FormItem label={t("issues.new.descriptionlabel")}>
                    <TextArea
                        autoSize={{ minRows: 2 }}
                        onChange={e =>
                            setState({ ...state, description: e.target.value })
                        }
                        placeholder={t("issues.new.descriptionplaceholder")}
                    />
                </FormItem>
                <FormItem label={t("issues.new.categorieslabel")}>
                    <Select
                        mode="multiple"
                        style={{ width: "100%" }}
                        placeholder={t("issues.new.categoriesplaceholder")}
                        defaultValue={state.tags}
                        onChange={handleTagChange}>
                        {props.categories.map((tag: any) => (
                            <Option value={tag.id}>{tag.title}</Option>
                        ))}
                    </Select>
                </FormItem>
                <FormItem>
                    <Button
                        style={{ width: "100%" }}
                        size="large"
                        // className="upload-demo-start"
                        type="primary"
                        onClick={handleUpload}
                        disabled={!isEnabled}
                        loading={uploading}>
                        {uploading ? t("Uploading") : t("issues.new.submit")}
                    </Button>
                </FormItem>
            </Form>
        </div>
    );
}

export default CreateIssue;
