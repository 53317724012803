import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import { Button, Card, Timeline, Tooltip } from "antd";
import {
    CaretDownOutlined,
    CaretUpOutlined,
    CheckCircleOutlined,
    ClockCircleOutlined,
} from "@ant-design/icons";
import { format } from "date-fns";
import { StyleGuide } from "styles/StyleGuide";
import { fetchOrgOrders } from "modules/orders/actions";
import { fetchOrgProducts } from "modules/products/actions";
import { truncate } from "./truncate";

const MIN_DISPLAY_LIMIT = 3;

export default function Orders({ orgId }: { orgId: string | null }) {
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (orgId) {
            dispatch(fetchOrgOrders(orgId));
            dispatch(fetchOrgProducts(orgId));
        }
    }, [orgId]);

    const orders = useAppSelector(state => state.orders.list);
    const loading = useAppSelector(state => state.orders.loading);
    const products = useAppSelector(state => state.products.orgProducts);
    const unit = useAppSelector(state => (orgId ? state.orgs.orgMap[orgId]?.unit : null));

    // const drawerCxt = useOfferDrawerContext();

    const [displayLimit, setDisplayLimit] = useState(MIN_DISPLAY_LIMIT);

    if (!orgId) {
        return <div>Tilbudet er ikke forbundet til en afdeling endnu</div>;
    }

    if (loading) {
        return <div>Loading...</div>;
    }
    if (orders.length < 1) {
        return <div>Der er ingen bestillinger</div>;
    }

    // const totalNetAmount = invoices.reduce((acc, i) => acc + i.netAmount, 0);
    const isCollapsed = displayLimit === MIN_DISPLAY_LIMIT;
    return (
        <div>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: 8,
                }}>
                <h4>Bestillinger</h4>
                <div>
                    <div
                        style={{
                            marginBottom: 4,
                            textAlign: "right",
                            color: StyleGuide.palette.grey700,
                        }}>
                        Beholdninger
                    </div>{" "}
                    <div style={{ display: "flex", gap: 8 }}>
                        {products.map(p => {
                            return (
                                <div>
                                    <Tooltip title={p.title}>
                                        <div
                                            style={{
                                                fontSize: 12,
                                                color: StyleGuide.palette.grey700,
                                            }}>
                                            {truncate(p.title, 20)}:
                                        </div>
                                    </Tooltip>{" "}
                                    <div style={{ textAlign: "right" }}>
                                        {p.storage} {unit}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <Timeline
                items={orders.slice(0, displayLimit).map((o: any) => {
                    if (o.status === "completed") {
                        return {
                            dot: (
                                <Tooltip title="Betalt">
                                    <CheckCircleOutlined />
                                </Tooltip>
                            ),
                            color: "green",
                            children: <OrderItem order={o} orgId={orgId} />,
                        };
                    }
                    if (o.status === "cancelled") {
                        return {
                            dot: (
                                <Tooltip title="Annuleret">
                                    <ClockCircleOutlined
                                        className={"timeline-clock-icon"}
                                    />
                                </Tooltip>
                            ),
                            color: "red",
                            children: <OrderItem order={o} orgId={orgId} />,
                        };
                    }
                    return {
                        dot: (
                            <Tooltip title="Afventer">
                                <ClockCircleOutlined className={"timeline-clock-icon"} />
                            </Tooltip>
                        ),
                        color: "default",
                        children: <OrderItem order={o} orgId={orgId} />,
                    };
                })}
            />
            {orders.length > MIN_DISPLAY_LIMIT && (
                <Button
                    block
                    icon={isCollapsed ? <CaretDownOutlined /> : <CaretUpOutlined />}
                    onClick={() =>
                        setDisplayLimit(isCollapsed ? 100 : MIN_DISPLAY_LIMIT)
                    }>
                    {isCollapsed ? "Se flere" : "Se færre"}
                </Button>
            )}
        </div>
    );
}

function OrderItem({ order, orgId }: { order: any; orgId: string }) {
    const i = order;
    const productMap = useAppSelector(state => state.products.orgProductsMap);
    const unit = useAppSelector(state => state.orgs.orgMap[orgId]?.unit);
    return (
        <Card size="small">
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                }}>
                <div style={{ display: "flex", gap: 8 }}>
                    <div>
                        <span
                            style={{
                                textAlign: "right",
                                color: StyleGuide.palette.grey700,
                                fontSize: 12,
                            }}>
                            Produkt
                        </span>
                        <Tooltip title={productMap[i.productId]?.title}>
                            <div>{truncate(productMap?.[i.productId]?.title, 20)}</div>
                        </Tooltip>
                    </div>
                    <div>
                        <span
                            style={{
                                textAlign: "right",
                                color: StyleGuide.palette.grey700,
                                fontSize: 12,
                            }}>
                            Mængde
                        </span>
                        <div>
                            {i.amount} {unit}
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: 8,
                    }}>
                    <div>
                        <span
                            style={{
                                textAlign: "right",
                                color: StyleGuide.palette.grey700,
                                fontSize: 12,
                            }}>
                            Oprettet
                        </span>
                        <div>{format(i.createTime.toDate(), "dd/MM/yyyy")}</div>
                    </div>
                </div>
            </div>
        </Card>
    );
}
