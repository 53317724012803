import { Card, Col, Grid, Layout, Row, Space } from "antd";
import HeaderBar from "../../Layout/HeaderBar";
import { Content } from "antd/lib/layout/layout";
import FooterBar from "../../Layout/FooterBar";
import useAppOffers from "hooks/useAppOffers";

import { useAppSelector } from "hooks";

import Loader from "components/Loader";
import SalesBars from "../Agreements/Graphs/Sales";
import useInvoices from "./useInvoices";
import { priceFormat } from "../Agreements/Offers/OffersTable";
import { StyleGuide } from "styles/StyleGuide";

const keyNames = {
    michaelRahbek: "Michael Rahbek",
    charlotteJensen: "Charlotte Jensen",
    allanSchmidt: "Allan Schmidt",
    allanMoeller: "Allan Møller",
    henningChristensen: "Henning Christensen",
    hansPedersen: "Hans Pedersen",
    charlotteOgMichael: "Charlotte Jensen/Michael Rahbek",
};

export default function Sales() {
    const { offers } = useAppOffers();
    const { loadingInvoices, invoices } = useInvoices();
    const orgs = useAppSelector(state => state.orgs.orgs);
    return (
        <>
            {/* <SideNav /> */}

            <Layout>
                <HeaderBar />
                <Content style={{ margin: "24px 24px 0" }}>
                    <Space />

                    <Space />
                    {loadingInvoices ? (
                        <Loader />
                    ) : (
                        <Row gutter={8}>
                            {Object.entries(invoices).map(([key, value]) => (
                                <Col key={key} md={12} style={{ marginBottom: 8 }}>
                                    <Card>
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                            }}>
                                            <h4 style={{ margin: 0, marginBottom: 16 }}>
                                                {
                                                    // @ts-ignore
                                                    keyNames[key] || "Ukendt"
                                                }
                                            </h4>
                                            <div>
                                                <div>
                                                    Udstående for året:{" "}
                                                    <span
                                                        style={{
                                                            color: StyleGuide.palette
                                                                .statusWarningDark,
                                                        }}>
                                                        {priceFormat.format(
                                                            value.reduce(
                                                                (acc, invoice) => {
                                                                    if (
                                                                        !invoice.paidInFull
                                                                    ) {
                                                                        return (
                                                                            acc +
                                                                            invoice.netAmount
                                                                        );
                                                                    }
                                                                    return acc;
                                                                },
                                                                0,
                                                            ),
                                                        )}
                                                    </span>
                                                </div>
                                                <div>
                                                    Total for året:{" "}
                                                    <span
                                                        style={{
                                                            color: StyleGuide.palette
                                                                .statusSuccessDark,
                                                        }}>
                                                        {priceFormat.format(
                                                            value.reduce(
                                                                (acc, invoice) => {
                                                                    if (
                                                                        invoice.paidInFull
                                                                    ) {
                                                                        return (
                                                                            acc +
                                                                            invoice.netAmount
                                                                        );
                                                                    }
                                                                    return acc;
                                                                },
                                                                0,
                                                            ),
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                height: 150,
                                                width: 600,
                                            }}>
                                            <SalesBars key={key} invoices={value} />
                                        </div>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    )}

                    {/* <ModalBilling appProducts={appProducts} /> */}
                </Content>
                <FooterBar />
            </Layout>
        </>
    );
}
