import { Alert } from "antd";
import { isBefore } from "date-fns";
import { useAppSelector } from "hooks";
import React from "react";
import { useTranslation } from "react-i18next";

export default function AuditDetailStatus() {
    const { t } = useTranslation();
    const audit = useAppSelector(state => state.auditSchedules.currentAuditSchedule);

    if (!audit?.isCompleted && isBefore(audit.dueDate.toDate(), new Date())) {
        const dueDate = audit.dueDate.toDate();
        return (
            <Alert
                style={{ paddingLeft: 24 }}
                type="error"
                message={
                    t("audit.banner.overdue") +
                    " " +
                    t("date.exactFormat", {
                        dayOfWeek: dueDate,
                        dayOfMonth: dueDate,
                        month: dueDate,
                        time: dueDate,
                    })
                }
                banner
            />
        );
    }
    if (audit?.isCompleted && audit?.hasOnHoldTasks) {
        return (
            <Alert
                style={{ paddingLeft: 24 }}
                type="warning"
                message={t("audit.banner.onhold")}
                banner
            />
        );
    }
    if (audit?.isCompleted && audit?.hasOnHoldTasks) {
        return (
            <Alert
                style={{ paddingLeft: 24 }}
                type="warning"
                message={t("audit.banner.needsresolution")}
                banner
            />
        );
    }
    if (audit?.isCompleted) {
        // @ts-ignore
        const completeTime = audit.completeTime?.toDate();
        return (
            <Alert
                style={{ paddingLeft: 24 }}
                type="success"
                message={
                    t("audit.banner.completed") +
                    " " +
                    t("date.exactFormatDateTimeYear", {
                        dayOfWeek: completeTime,
                        dayOfMonth: completeTime,
                        month: completeTime,
                        time: completeTime,
                        year: completeTime,
                    })
                }
                banner
            />
        );
    }
    if (!audit?.isCompleted && !isBefore(audit.dueDate?.toDate(), new Date())) {
        const dueDate = audit.dueDate.toDate();
        return (
            <Alert
                style={{ paddingLeft: 24 }}
                type="info"
                message={
                    t("audit.banner.ongoing") +
                    " " +
                    t("date.exactFormatWithYear", {
                        dayOfWeek: dueDate,
                        dayOfMonth: dueDate,
                        month: dueDate,
                        time: dueDate,
                        year: dueDate,
                    })
                }
                banner
            />
        );
    }
    return null;
}
