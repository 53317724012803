import { Divider, Drawer } from "antd";
import { useOfferDrawerContext } from "../ContextDrawer/OfferContext";
import { AppOffer } from "hooks/useAppOffers";
import Invoices from "./Invoices";
import { useAppSelector } from "hooks";
import Orders from "./Orders";

export default function DrawerOffer({ offers }: { offers: AppOffer[] }) {
    const { state, dispatch } = useOfferDrawerContext();

    const onClose = () => {
        dispatch({ type: "CLOSE_DRAWER" });
    };
    const open = Boolean(state.offerId);

    const offer = offers.find(offer => offer.id === state.offerId);

    return (
        <Drawer
            title={`Tilbud - ${offer?.title} - ${offer?.contact.att}`}
            width={720}
            onClose={onClose}
            open={Boolean(open)}
            bodyStyle={{ paddingBottom: 80 }}
            extra={
                <div />
                // ids?.billingId && (
                //     <Popconfirm
                //         title={"Er du sikker på at du vil slette dette abonnement?"}
                //         onConfirm={onDelete}
                //         okText={"Slet"}
                //         cancelText={"Annuler"}>
                //         <Button>Slet</Button>
                //     </Popconfirm>
                // )
            }>
            <DrawerContent offer={offer} offerId={state.offerId} orgId={state.orgId} />
        </Drawer>
    );
}

function DrawerContent({
    offer,
    offerId,
    orgId,
}: {
    offer: AppOffer | undefined;
    offerId: string | null;
    orgId: string | null;
}) {
    const org = useAppSelector(state => (orgId ? state.orgs.orgMap[orgId] : null));

    if (!offerId || !offer) {
        return null;
    }

    return (
        <div>
            <h4>Tilbud forbundet til: {org?.title}</h4>
            <Divider />
            <Invoices orgId={orgId} hallerupCustomerId={org?.hallerupCustomerId} />
            <Divider />
            <Orders orgId={orgId} />
        </div>
    );
}
