import { Button, Form, Input } from "antd";
import React, { useState } from "react";
import FormItem from "antd/es/form/FormItem";
import OrgsSelect from "Views/DashboardOverAllContainer/OrgsSelect";
import { IOrg } from "modules/orgs/interfaces";
import { updateOffer } from "./OffersTable";
import { OfferStatus } from "../Context/offerReducer";
import { StyleGuide } from "styles/StyleGuide";
import { useTranslation } from "react-i18next";

const formItemLayout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 14 },
};

export default function AcceptForm({
    offerId,
    closeModal,
    orgs,
}: {
    offerId: string;
    closeModal: VoidFunction;
    orgs: IOrg[];
}) {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [orgId, setOrgId] = useState<string>("");

    const handleSubmit = async (values: any) => {
        console.log(values);

        try {
            if (!offerId || !orgId) {
                throw new Error("Missing offer id or org id");
            }
            await updateOffer(offerId, {
                status: OfferStatus.ACCEPTED,
                orgId,
                acceptTime: new Date(),
            });
            closeModal();
        } catch (error) {
            console.error(error);
            alert(error);
        }
    };

    return (
        <Form
            form={form}
            layout="horizontal"
            initialValues={{
                incompleteTasks: true,
                completedTasks: true,
                dateInterval: undefined,
            }}
            onFinish={handleSubmit}>
            <FormItem
                {...formItemLayout}
                name="orgId"
                style={{ width: "100%" }}
                label="Vælg firma"
                required
                rules={[
                    {
                        required: false,
                        message: "Please select a template!",
                    },
                ]}>
                <OrgsSelect
                    orgs={orgs}
                    orgIds={orgId}
                    setOrgIds={(newValue: string | string[]) => {
                        setOrgId(newValue as string);
                    }}
                />
            </FormItem>
            <div style={{ marginTop: 16 }} />
            <FormItem
                {...formItemLayout}
                name="invoiceContact"
                style={{ paddingTop: 8, width: "100%" }}
                label={
                    <div style={{ textAlign: "left", paddingBottom: 16 }}>
                        <div style={{ marginTop: 12 }}>Betalings kontaktperson</div>
                        <div
                            style={{
                                textAlign: "left",
                                color: StyleGuide.palette.grey600,
                                fontSize: 12,
                                lineHeight: 0.8,
                            }}>
                            Eksempel: Ib Ibsen
                        </div>
                    </div>
                }
                rules={[
                    {
                        required: true,
                        message: "Venligst skriv kontaktpersons navn",
                    },
                ]}>
                <Input type="text" placeholder={t("Kim Ibsen")} />
            </FormItem>
            <div style={{ marginTop: 16 }} />
            <FormItem
                {...formItemLayout}
                style={{ paddingTop: 8, width: "100%" }}
                name="invoiceEmail"
                label={
                    <div style={{ textAlign: "left", paddingBottom: 16 }}>
                        <div style={{ marginTop: 12 }}>Faktura email</div>

                        <div
                            style={{
                                textAlign: "left",
                                color: StyleGuide.palette.grey600,
                                fontSize: 12,
                                lineHeight: 0.8,
                            }}>
                            {/* <div>Eksempel: invoice@arla.dk</div> */}
                        </div>
                    </div>
                }
                rules={[
                    {
                        required: true,
                        message: "Venligst skriv kontaktpersons navn",
                    },
                ]}>
                <Input type="text" placeholder={t("invoice@arla.dk")} />
            </FormItem>
            <div style={{ marginTop: 16 }} />
            <FormItem
                {...formItemLayout}
                name="deliveryAddress"
                style={{ paddingTop: 8, width: "100%" }}
                label={
                    <div style={{ textAlign: "left", paddingBottom: 16 }}>
                        <div style={{ marginTop: 12 }}>Leveringsadresse</div>
                        <div
                            style={{
                                textAlign: "left",
                                color: StyleGuide.palette.grey600,
                                fontSize: 12,
                                lineHeight: 0.8,
                            }}>
                            {/* Eksempel: invoice@arla.dk */}
                        </div>
                    </div>
                }
                rules={[
                    {
                        required: true,
                        message: "Venligst skriv kontaktpersons navn",
                    },
                ]}>
                <Input type="text" placeholder={t("Støvvej 123, 1234 Horsens")} />
            </FormItem>

            <FormItem style={{ width: "100%" }}>
                <Button type="primary" htmlType="submit" block>
                    Gem tilbud til firma
                </Button>
            </FormItem>
        </Form>
    );
}
