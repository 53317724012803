import { Button, Card, Divider, InputNumber } from "antd";
import { sweepingGranulesLiterPrice } from "../Agreements/Context/offerConfigs";
import { getCurrencyFormat } from "./Offer";
import { getDiscountFactor, useOfferContext } from "../Agreements/Context/OfferContext";

export default function LiterPriceCardGranules() {
    const { state, dispatch } = useOfferContext();

    const discount = state.discountGranules;
    const price = state.sweepingGranules * sweepingGranulesLiterPrice;
    return (
        <Card
            title={`Liter fejegranulat - pr. ${"år"} - ${getCurrencyFormat(
                sweepingGranulesLiterPrice,
            )} pr. liter`}>
            <table className="price-table">
                <thead>
                    <th></th>

                    <th style={{ textAlign: "right" }}></th>

                    <th style={{ textAlign: "right" }}>Liter</th>
                    <th style={{ textAlign: "right" }}>Pris</th>
                </thead>
                <tr>
                    <td style={{ width: "100%" }}>Liter fejegranulat: </td>
                    <td
                        style={{
                            paddingLeft: 24,
                            whiteSpace: "nowrap",
                            textAlign: "right",
                        }}></td>
                    <td
                        style={{
                            paddingLeft: 24,
                            whiteSpace: "nowrap",
                            textAlign: "right",
                        }}>
                        {new Intl.NumberFormat("da-DK", {
                            style: "unit",
                            unit: "liter",
                            maximumFractionDigits: 0,
                        }).format(state.sweepingGranules)}
                    </td>
                    <td
                        style={{
                            paddingLeft: 24,
                            whiteSpace: "nowrap",
                            textAlign: "right",
                        }}>
                        {getCurrencyFormat(price)}
                    </td>
                    <td style={{ paddingLeft: 24, textAlign: "right" }}></td>
                </tr>
                {state.priceType && (
                    <>
                        <tr>
                            <td colSpan={8}>
                                <Divider style={{ margin: "8px 0" }} />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={3}>Rabat til kunder over 50.000m2</td>
                            <td colSpan={2}>
                                <InputNumber<number>
                                    value={discount}
                                    min={0}
                                    max={20}
                                    formatter={value => `${value}%`}
                                    parser={value =>
                                        value?.replace("%", "") as unknown as number
                                    }
                                    onChange={value => {
                                        dispatch({
                                            type: "SET_DISCOUNT_GRANULES",
                                            payload: value,
                                        });
                                    }}
                                />
                            </td>
                        </tr>
                        {discount !== null ? (
                            <>
                                <tr>
                                    <td colSpan={8}>
                                        <Divider style={{ margin: "8px 0" }} />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={1}>Pris med {discount}% rabat:</td>
                                    <td
                                        colSpan={1}
                                        style={{
                                            textAlign: "right",
                                            fontWeight: 700,
                                            textDecoration: "underline",
                                        }}></td>
                                    <td colSpan={1}></td>
                                    <td
                                        colSpan={1}
                                        style={{
                                            textAlign: "right",
                                            fontWeight: 700,
                                            textDecoration: "underline",
                                        }}>
                                        {getCurrencyFormat(
                                            price * getDiscountFactor(discount),
                                        )}
                                    </td>
                                    <td
                                        colSpan={1}
                                        style={{
                                            textAlign: "right",
                                            fontWeight: 700,
                                            textDecoration: "underline",
                                        }}></td>
                                    <td colSpan={1}></td>
                                </tr>
                            </>
                        ) : null}
                    </>
                )}
            </table>
        </Card>
    );
}
