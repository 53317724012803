import { CalendarMode } from "antd/lib/calendar/generateCalendar";
import { RecurringOptions } from "general/enums";
import { AuditGuide } from "modules/auditTemplates/types";
import { CustomField } from "modules/audits/types";

// import { RecurringOptions } from "../../../functions/src/enums";
export const AUDIT_SCHEDULES_FETCH_SUCCEEDED = "AUDIT_SCHEDULES_FETCH_SUCCEEDED";
export const AUDIT_SCHEDULES_FETCH_FAILED = "AUDIT_SCHEDULES_FETCH_FAILED";
export const AUDIT_SCHEDULES_FETCH_REQUESTED = "AUDIT_SCHEDULES_FETCH_REQUESTED";

export const INCOMPLETE_AUDIT_SCHEDULES_FETCH_SUCCEEDED =
    "INCOMPLETE_AUDIT_SCHEDULES_FETCH_SUCCEEDED";
export const INCOMPLETE_AUDIT_SCHEDULES_FETCH_FAILED =
    "INCOMPLETE_AUDIT_SCHEDULES_FETCH_FAILED";
export const INCOMPLETE_AUDIT_SCHEDULES_FETCH_REQUESTED =
    "INCOMPLETE_AUDIT_SCHEDULES_FETCH_REQUESTED";

export const ON_HOLD_AUDIT_SCHEDULES_FETCH_SUCCEEDED =
    "ON_HOLD_AUDIT_SCHEDULES_FETCH_SUCCEEDED";
export const ON_HOLD_AUDIT_SCHEDULES_FETCH_FAILED =
    "ON_HOLD_AUDIT_SCHEDULES_FETCH_FAILED";
export const ON_HOLD_AUDIT_SCHEDULES_FETCH_REQUESTED =
    "ON_HOLD_AUDIT_SCHEDULES_FETCH_REQUESTED";

export const NEEDS_RESOLUTION_AUDIT_SCHEDULES_FETCH_SUCCEEDED =
    "NEEDS_RESOLUTION_AUDIT_SCHEDULES_FETCH_SUCCEEDED";
export const NEEDS_RESOLUTION_AUDIT_SCHEDULES_FETCH_FAILED =
    "NEEDS_RESOLUTION_AUDIT_SCHEDULES_FETCH_FAILED";
export const NEEDS_RESOLUTION_AUDIT_SCHEDULES_FETCH_REQUESTED =
    "NEEDS_RESOLUTION_AUDIT_SCHEDULES_FETCH_REQUESTED";

export const OVERDUE_AUDIT_SCHEDULES_FETCH_SUCCEEDED =
    "OVERDUE_AUDIT_SCHEDULES_FETCH_SUCCEEDED";
export const OVERDUE_AUDIT_SCHEDULES_FETCH_FAILED =
    "OVERDUE_AUDIT_SCHEDULES_FETCH_FAILED";
export const OVERDUE_AUDIT_SCHEDULES_FETCH_REQUESTED =
    "OVERDUE_AUDIT_SCHEDULES_FETCH_REQUESTED";

export const COMPLETE_AUDIT_SCHEDULES_FETCH_SUCCEEDED =
    "COMPLETE_AUDIT_SCHEDULES_FETCH_SUCCEEDED";
export const COMPLETE_AUDIT_SCHEDULES_FETCH_FAILED =
    "COMPLETE_AUDIT_SCHEDULES_FETCH_FAILED";
export const COMPLETE_AUDIT_SCHEDULES_FETCH_REQUESTED =
    "COMPLETE_AUDIT_SCHEDULES_FETCH_REQUESTED";

export const AUDIT_SCHEDULE_FETCH_SUCCEEDED = "AUDIT_SCHEDULE_FETCH_SUCCEEDED";
export const AUDIT_SCHEDULE_FETCH_FAILED = "AUDIT_SCHEDULE_FETCH_FAILED";
export const AUDIT_SCHEDULE_FETCH_REQUESTED = "AUDIT_SCHEDULE_FETCH_REQUESTED";

export const AUDIT_SCHEDULE_UPDATE_SUCCEEDED = "AUDIT_SCHEDULE_UPDATE_SUCCEEDED";
export const AUDIT_SCHEDULE_UPDATE_FAILED = "AUDIT_SCHEDULE_UPDATE_FAILED";
export const AUDIT_SCHEDULE_UPDATE_REQUESTED = "AUDIT_SCHEDULE_UPDATE_REQUESTED";

export const AUDIT_SCHEDULE_CREATE_SUCCEEDED = "AUDIT_SCHEDULE_CREATE_SUCCEEDED";
export const AUDIT_SCHEDULE_CREATE_FAILED = "AUDIT_SCHEDULE_CREATE_FAILED";
export const AUDIT_SCHEDULE_CREATE_REQUESTED = "AUDIT_SCHEDULE_CREATE_REQUESTED";

export const AUDIT_SCHEDULE_DELETE_SUCCEEDED = "AUDIT_SCHEDULE_DELETE_SUCCEEDED";
export const AUDIT_SCHEDULE_DELETE_FAILED = "AUDIT_SCHEDULE_DELETE_FAILED";
export const AUDIT_SCHEDULE_DELETE_REQUESTED = "AUDIT_SCHEDULE_DELETE_REQUESTED";

export const AUDIT_SCHEDULE_TASKS_FETCH_SUCCEEDED =
    "AUDIT_SCHEDULE_TASKS_FETCH_SUCCEEDED";
export const AUDIT_SCHEDULE_TASKS_FETCH_FAILED = "AUDIT_SCHEDULE_TASKS_FETCH_FAILED";
export const AUDIT_SCHEDULE_TASKS_FETCH_REQUESTED =
    "AUDIT_SCHEDULE_TASKS_FETCH_REQUESTED";

export const AUDIT_SCHEDULE_TASK_ADD_SUCCEEDED = "AUDIT_SCHEDULE_TASK_ADD_SUCCEEDED";
export const AUDIT_SCHEDULE_TASK_ADD_FAILED = "  AUDIT_SCHEDULE_TASK_ADD_FAILED";
export const AUDIT_SCHEDULE_TASK_ADD_REQUESTED = "  AUDIT_SCHEDULE_TASK_ADD_REQUESTED";

export const AUDIT_SCHEDULE_TASK_DELETE_SUCCEEDED =
    "AUDIT_SCHEDULE_TASK_DELETE_SUCCEEDED";
export const AUDIT_SCHEDULE_TASK_DELETE_FAILED = "AUDIT_SCHEDULE_TASK_DELETE_FAILED";
export const AUDIT_SCHEDULE_TASK_DELETE_REQUESTED =
    "AUDIT_SCHEDULE_TASK_DELETE_REQUESTED";

export const AUDIT_SCHEDULE_TASK_UPDATE_SUCCEEDED =
    "AUDIT_SCHEDULE_TASK_UPDATE_SUCCEEDED";
export const AUDIT_SCHEDULE_TASK_UPDATE_FAILED = "AUDIT_SCHEDULE_TASK_UPDATE_FAILED";
export const AUDIT_SCHEDULE_TASK_UPDATE_REQUESTED =
    "AUDIT_SCHEDULE_TASK_UPDATE_REQUESTED";

export const AUDIT_SCHEDULE_ADD_SUCCEEDED = "AUDIT_SCHEDULE_ADD_SUCCEEDED";
export const AUDIT_SCHEDULE_ADD_FAILED = "  AUDIT_SCHEDULE_ADD_FAILED";
export const AUDIT_SCHEDULE_ADD_REQUESTED = "  AUDIT_SCHEDULE_ADD_REQUESTED";

export const SET_CURRENT_TAB = "SET_CURRENT_TAB";
export const AUDIT_CALENDAR_CHANGE = "AUDIT_CALENDAR_CHANGE";

export enum AuditSourceType {
    GROUP_TEMPLATE = "GROUP_TEMPLATE",
}

export interface DBAuditSchedule {
    groupId: string | null;
    templateTitle: string;
    templateId: string;
    templateNote?: string;
    recurringOption: any; //RecurringOptions;
    dueDate: { toDate: () => Date };
    createTime: Date;
    isCompleted: boolean;
    guide: AuditGuide | null;
    // based on tasks
    needsResolution: boolean;
    startDaysBeforeDueDate?: number;
    notifyDateAfterDueDate?: { toDate: () => Date } | null;
    note?: string;
    auditTemplateCategory?: string | null;
    auditSourceType?: AuditSourceType;
    createBy: { id: string; name: string };
    responsible: {
        id: string;
        displayName: string;
    } | null;
}
export interface AuditSchedule {
    auditTemplateCategory: string | null;
    templateTitle: string;
    templateNote?: string;
    templateId: string;
    recurringId: any; //RecurringOptions;
    recurringOption: RecurringOptions; //RecurringOptions;
    dueDate: { toDate: () => Date };
    startDate: { toDate: () => Date };
    startDaysBeforeDueDate?: number | null;
    notifyDaysAfterDueDate?: number | null;
    createTime: Date;
    isCompleted?: boolean;
    guide: AuditGuide | null;
    // based on tasks
    needsResolution: boolean;
    hasOnHoldTasks?: boolean;
    tasks: { [id: string]: AuditScheduleTask };
    auditor?: string;
    id: string;
    completeTime?: Date;
    note?: string;
    responsible: {
        id: string;
        displayName: string;
    } | null;
    auditSourceType?: AuditSourceType;
}

export interface AuditScheduleTask {
    id: string;
    description: string;
    order?: number;
    deviation: number | string;
    issueDescription: string;
    isCompleted?: boolean;
    createTime: { toDate: () => Date };
    completeTime?: Date;
    onHoldDescription?: string;
    isOnHold?: boolean;
    onHoldTime?: Date;
    onHoldBy?: {
        uid: string;
        name: string;
    };
    customFields?: (CustomField & { value?: null | string | number })[];
}

export interface AuditScheduleState {
    auditSchedules: AuditSchedule[];
    auditSchedulesMap: { [id: string]: AuditSchedule };
    inCompleteAuditSchedules: AuditSchedule[];
    onHoldAuditSchedules: AuditSchedule[];
    needsResolutionAuditSchedules: AuditSchedule[];
    overdueAuditSchedules: AuditSchedule[];
    completeAuditSchedules: AuditSchedule[];
    currentAuditSchedule: AuditSchedule;
    calendarDateStart: Date;
    calendarDateEnd: Date;
    calendarMode: "dayGridMonth" | "multiMonthYear";
    loading: boolean;
    isLoadingCurrent: boolean;
    addRequestState: { [id: string]: boolean };
    currentTab: "completed" | "incompleted";
}

// document
interface FetchAuditSchedule {
    type: typeof AUDIT_SCHEDULE_FETCH_REQUESTED;
    payload: { orgId: string };
}
interface FetchAuditScheduleSuccess {
    type: typeof AUDIT_SCHEDULE_FETCH_SUCCEEDED;
    payload: { data: DBAuditSchedule };
}
interface FetchAuditScheduleFailed {
    type: typeof AUDIT_SCHEDULE_FETCH_FAILED;
    payload: {};
}
// collection
interface FetchAuditSchedules {
    type: typeof AUDIT_SCHEDULES_FETCH_REQUESTED;
    payload: { orgId: string };
}
interface FetchAuditSchedulesSuccess {
    type: typeof AUDIT_SCHEDULES_FETCH_SUCCEEDED;
    payload: { data: AuditSchedule[] };
}

interface FetchAuditSchedulesFailed {
    type: typeof AUDIT_SCHEDULES_FETCH_FAILED;
    payload: {};
}
// INCOMPLETE
interface FetchInCompleteAuditSchedules {
    type: typeof INCOMPLETE_AUDIT_SCHEDULES_FETCH_REQUESTED;
    payload: { orgId: string };
}
interface FetchInCompleteAuditSchedulesSuccess {
    type: typeof INCOMPLETE_AUDIT_SCHEDULES_FETCH_SUCCEEDED;
    payload: { data: AuditSchedule[] };
}

interface FetchInCompleteAuditSchedulesFailed {
    type: typeof INCOMPLETE_AUDIT_SCHEDULES_FETCH_FAILED;
    payload: {};
}
// ON HOLD
interface FetchOnHoldAuditSchedules {
    type: typeof ON_HOLD_AUDIT_SCHEDULES_FETCH_REQUESTED;
    payload: { orgId: string };
}
interface FetchOnHoldAuditSchedulesSuccess {
    type: typeof ON_HOLD_AUDIT_SCHEDULES_FETCH_SUCCEEDED;
    payload: { data: AuditSchedule[] };
}

interface FetchOnHoldAuditSchedulesFailed {
    type: typeof ON_HOLD_AUDIT_SCHEDULES_FETCH_FAILED;
    payload: {};
}
// NEEDS RESOLUTION
interface FetchNeedsResolutionAuditSchedules {
    type: typeof NEEDS_RESOLUTION_AUDIT_SCHEDULES_FETCH_REQUESTED;
    payload: { orgId: string };
}
interface FetchNeedsResolutionAuditSchedulesSuccess {
    type: typeof NEEDS_RESOLUTION_AUDIT_SCHEDULES_FETCH_SUCCEEDED;
    payload: { data: AuditSchedule[] };
}

interface FetchNeedsResolutionAuditSchedulesFailed {
    type: typeof NEEDS_RESOLUTION_AUDIT_SCHEDULES_FETCH_FAILED;
    payload: {};
}
// COMPLETE
interface FetchCompleteAuditSchedules {
    type: typeof COMPLETE_AUDIT_SCHEDULES_FETCH_REQUESTED;
    payload: { orgId: string };
}
interface FetchCompleteAuditSchedulesSuccess {
    type: typeof COMPLETE_AUDIT_SCHEDULES_FETCH_SUCCEEDED;
    payload: { data: AuditSchedule[] };
}

interface FetchCompleteAuditSchedulesFailed {
    type: typeof COMPLETE_AUDIT_SCHEDULES_FETCH_FAILED;
    payload: {};
}

// OVERDUE
interface FetchOverdueAuditSchedules {
    type: typeof OVERDUE_AUDIT_SCHEDULES_FETCH_REQUESTED;
    payload: { orgId: string };
}
interface FetchOverdueAuditSchedulesSuccess {
    type: typeof OVERDUE_AUDIT_SCHEDULES_FETCH_SUCCEEDED;
    payload: { data: AuditSchedule[] };
}

interface FetchOverdueAuditSchedulesFailed {
    type: typeof OVERDUE_AUDIT_SCHEDULES_FETCH_FAILED;
    payload: {};
}
interface FetchAuditScheduleTasks {
    type: typeof AUDIT_SCHEDULE_TASKS_FETCH_REQUESTED;
    payload: { orgId: string };
}
interface FetchAuditScheduleTasksSuccess {
    type: typeof AUDIT_SCHEDULE_TASKS_FETCH_SUCCEEDED;
    payload: { data: { [id: string]: AuditScheduleTask } };
}
interface FetchAuditScheduleTasksFailed {
    type: typeof AUDIT_SCHEDULE_TASKS_FETCH_FAILED;
    payload: { orgId: string };
}
interface AuditCalendarChange {
    type: typeof AUDIT_CALENDAR_CHANGE;
    payload: { dateStart: Date; dateEnd: Date; mode: "dayGridMonth" | "multiMonthYear" };
}
interface AddAuditSchedule {
    type: typeof AUDIT_SCHEDULE_ADD_REQUESTED;
    payload: { auditId: string };
}
interface AddAuditScheduleSuccess {
    type: typeof AUDIT_SCHEDULE_ADD_SUCCEEDED;
    payload: { auditId: string };
}

export type AuditScheduleActionTypes =
    | FetchAuditSchedule
    | FetchAuditScheduleSuccess
    | FetchAuditScheduleFailed
    | FetchAuditSchedules
    | FetchAuditSchedulesSuccess
    | FetchAuditSchedulesFailed
    | FetchAuditScheduleTasks
    | FetchAuditScheduleTasksSuccess
    | FetchAuditScheduleTasksFailed
    | FetchInCompleteAuditSchedules
    | FetchInCompleteAuditSchedulesSuccess
    | FetchInCompleteAuditSchedulesFailed
    | FetchCompleteAuditSchedules
    | FetchCompleteAuditSchedulesSuccess
    | FetchCompleteAuditSchedulesFailed
    | FetchOverdueAuditSchedules
    | FetchOverdueAuditSchedulesSuccess
    | FetchOverdueAuditSchedulesFailed
    | AuditCalendarChange
    | AddAuditSchedule
    | AddAuditScheduleSuccess
    | FetchOnHoldAuditSchedules
    | FetchOnHoldAuditSchedulesSuccess
    | FetchOnHoldAuditSchedulesFailed
    | FetchNeedsResolutionAuditSchedules
    | FetchNeedsResolutionAuditSchedulesSuccess
    | FetchNeedsResolutionAuditSchedulesFailed;
