import { createContext, useContext, useReducer } from "react";
import {
    initialState,
    OfferDrawerAction,
    offerDrawerReducer,
    OfferDrawerState,
} from "./offerReducer";

interface OfferDrawerContextProps {
    state: OfferDrawerState;
    dispatch: React.Dispatch<OfferDrawerAction>;
}

const OfferDrawerContext = createContext<OfferDrawerContextProps | undefined>(undefined);

export const OfferDrawerProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const [state, dispatch] = useReducer(offerDrawerReducer, initialState);

    return (
        <OfferDrawerContext.Provider value={{ state, dispatch }}>
            {children}
        </OfferDrawerContext.Provider>
    );
};

export const useOfferDrawerContext = () => {
    const context = useContext(OfferDrawerContext);
    if (!context) {
        throw new Error("useOfferContext must be used within an OfferProvider");
    }
    return context;
};
