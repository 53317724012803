import { useState, useRef } from "react";
import { Table, Input, Button, Avatar, Tooltip, Badge } from "antd";
import Highlighter from "react-highlight-words";
import { Link, useHistory } from "react-router-dom";
import { DeleteCompany, GroupedOrgs } from "./index";
import { SearchOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { AppState } from "../../store";
import { IOrg, OrgStatus, OrgStatusText, Roles } from "../../modules/orgs/interfaces";
import { isAdmin, AdminRoleOnly, isAdminAndMichael } from "../../components/AdminOnly";
import { useTranslation } from "react-i18next";
import { getInitials } from "../../general/initials";
import { RoleText } from "../Members/components/columns";
import GroupSelect from "./GroupSelect";
import { DBGroupShape } from "../../modules/groups/types";
import { StyleGuide } from "styles/StyleGuide";
import { ExpandableConfig } from "antd/es/table/interface";
import { format } from "date-fns";
import GroupedIcon from "assets/icons/GroupedIcon";

const colors = [
    StyleGuide.palette.infoDark,
    StyleGuide.palette.info,
    StyleGuide.palette.infoLight,
    StyleGuide.palette.infoLighter,
];
const colorsText = [
    StyleGuide.palette.infoLighter,
    StyleGuide.palette.infoLight,
    StyleGuide.palette.info,
    StyleGuide.palette.infoDark,
];

export function getColor(index: number) {
    return colors[index];
}
export function getTextColor(index: number) {
    return colorsText[index];
}

interface IData {
    id: string;
    title: string;
    createTime: string;
}

interface IProps {
    data: GroupedOrgs[];
    groups: DBGroupShape[];
    deleteOrg: (orgId: string) => void;
}

const skipCol = {
    children: "",
    props: {
        colSpan: 0,
    },
};

function TableList(props: IProps) {
    const history = useHistory();
    const uid = useSelector((state: AppState) => state.user.details.uid);

    const [expandedKeys, setExpandedKeys] = useState<string[]>(
        props.data.filter(org => org.subGroup).map(org => org.subGroup) as string[],
    );
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);
    const { t } = useTranslation();

    const displayAll = isAdminAndMichael(uid);

    const getColumnSearchProps = (dataIndex: string) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }: {
            setSelectedKeys: (event: string | string[]) => void;
            selectedKeys: string[];
            confirm: () => void;
            clearFilters: () => void;
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={t("home.table.searchPlaceholder")}
                    value={selectedKeys[0]}
                    onChange={e =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 200, marginBottom: 8, display: "block" }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}>
                    {t("home.table.search")}
                </Button>
                <Button
                    onClick={() => handleReset(clearFilters)}
                    size="small"
                    style={{ width: 90 }}>
                    {t("home.table.searchReset")}
                </Button>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
        ),
        onFilter: (value: string, record: any) => {
            if (record?.orgs) {
                return (
                    record.orgs
                        .map((org: any) => org[dataIndex])
                        .join(" ")
                        // .toString()
                        .toLowerCase()
                        .includes(value.toLowerCase())
                );
            }
            return record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase());
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
            if (visible) {
                // @ts-ignore
                setTimeout(() => searchInput.current.select());
            }
        },
        render: (text: string, record: any) => {
            if (searchedColumn === dataIndex) {
                return (
                    <Link to={`orgs/${record.id}/dashboard`}>
                        <Highlighter
                            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                            searchWords={[searchText]}
                            autoEscape
                            textToHighlight={text.toString()}
                        />
                    </Link>
                );
            }
            return <Link to={`orgs/${record.id}/dashboard`}>{text}</Link>;
        },
    });

    const handleSearch = (
        selectedKeys: string[],
        confirm: () => void,
        dataIndex: string,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
        // this.setState({
        //     searchText: selectedKeys[0],
        //     searchedColumn: dataIndex,
        // });
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText("");
    };

    const columns = [
        {
            title: t("home.table.companyname"),
            dataIndex: "title",
            key: "title",
            width: "30%",
            ...getColumnSearchProps("title"),
            render: (text: string, record: any) => {
                if (record.subGroup && !record.nested) {
                    return {
                        children: (
                            <div style={{ display: "flex", alignItems: "center" }}>
                                {/* <GroupedIcon width={18} /> */}
                                <div style={{ fontWeight: "bold" }}>{text}</div>
                            </div>
                        ),
                        props: {
                            colSpan: !displayAll ? 3 : 5,
                        },
                    };
                }
                return <Link to={`orgs/${record.id}/dashboard`}>{text}</Link>;
            },
        },
        {
            title: t("home.table.members"),
            dataIndex: "members",
            key: "members",
            width: "20%",
            render: (members: any, record: any) => {
                if (record.subGroup && !record.nested) {
                    return skipCol;
                }

                const admins = Object.keys(members).filter(
                    (id: string) =>
                        members[id].role === Roles.ADMIN &&
                        (!isAdmin(uid)
                            ? members[id].email !== "info@akstotal.dk" &&
                              members[id].email !== "info@aks2tal.com"
                            : true),
                );

                const sales = Object.keys(members).filter(
                    (id: string) => members[id].role === Roles.SALES,
                );
                const departmentExecutive = Object.keys(members).filter(
                    (id: string) => members[id].role === Roles.DEPARTMENT_EXECUTIVE,
                );
                const cleaner = Object.keys(members).filter(
                    (id: string) => members[id].role === Roles.CLEANER,
                );
                const all = [admins, sales, departmentExecutive, cleaner];

                return (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        {all.map((role, index) => (
                            <Avatar.Group maxCount={3}>
                                {members &&
                                    role
                                        ?.sort((a, b) => {
                                            const textA =
                                                members[a]?.name || members[a]?.email;
                                            const textB =
                                                members[b]?.name || members[b]?.email;
                                            return textA?.localeCompare(textB);
                                        })
                                        ?.map(key => {
                                            const initial =
                                                getInitials(members[key]?.name) ||
                                                getInitials(members[key]?.email);
                                            return (
                                                <Tooltip
                                                    title={
                                                        <div>
                                                            {members[key]?.name ||
                                                                members[key]?.email}
                                                            {members[key]?.role !==
                                                                Roles.CLEANER && (
                                                                <>
                                                                    <br />
                                                                    <RoleText
                                                                        role={
                                                                            members[key]
                                                                                ?.role
                                                                        }
                                                                    />
                                                                </>
                                                            )}
                                                        </div>
                                                    }
                                                    placement="top">
                                                    <Avatar
                                                        style={{
                                                            textTransform: "capitalize",
                                                            backgroundColor:
                                                                getColor(index),
                                                            color: getTextColor(index),
                                                        }}>
                                                        {initial}
                                                    </Avatar>
                                                </Tooltip>
                                            );
                                        })}
                            </Avatar.Group>
                        ))}
                    </div>
                );
            },
        },
        {
            title: t("home.table.createTime"),
            dataIndex: "createTime",
            width: "10%",
            key: "createTime",
            render: (text: string, record: any) => {
                if (record.subGroup && !record.nested) {
                    return skipCol;
                }
                // @ts-ignore
                return format(new Date(text.toDate()), "dd-MM-yyyy");
            },
            // width: "20%",
        },
        {
            title: t("home.table.status"),
            key: "status",
            width: "10%",
            render: ({ status }: { status: OrgStatus }, record: any) => {
                if (record.subGroup && !record.nested) {
                    return skipCol;
                }
                switch (status) {
                    case OrgStatus.IN_PROGRESS:
                        return <Badge status="processing" text={t(OrgStatusText[1])} />;

                    case OrgStatus.ACTIVE:
                        return <Badge status="success" text={t(OrgStatusText[2])} />;

                    case OrgStatus.INACTIVE:
                        return <Badge status="error" text={t(OrgStatusText[3])} />;

                    default:
                        return <Badge status="default" text={t(OrgStatusText[0])} />;
                }
            },
        },
        {
            title: t("Gruppe"),
            key: "groupId",
            width: "20%",
            render: ({ id, groupId }: { id: string; groupId?: string }, record: any) => {
                if (record.subGroup && !record.nested) {
                    return skipCol;
                }
                return <GroupSelect groupId={groupId} groups={props.groups} orgId={id} />;
            },
        },
        {
            title: "", //t("home.table.delete"),
            key: "action",
            width: "10%",
            render: ({ id }: any, record: any) => {
                if (record.subGroup && !record.nested) {
                    return (
                        <Button
                            onClick={() =>
                                history.push(`/dashboard/sub?subGroupId=${id}`)
                            }>
                            {t("Dashboard")}
                        </Button>
                    );
                }
                return (
                    <AdminRoleOnly orgId={id} key={id}>
                        <DeleteCompany deleteOrg={() => props.deleteOrg(id)} />
                    </AdminRoleOnly>
                );
            },
        },
    ].filter(i => (!displayAll ? i.key !== "status" && i.key !== "groupId" : i));

    const onExpandRow = (key: string) => {
        const newExpandedKeys = expandedKeys.includes(key)
            ? expandedKeys.filter(expandedKey => expandedKey !== key) // toggle off
            : [...expandedKeys, key]; // toggle on
        setExpandedKeys(newExpandedKeys);
    };

    const expandedRowConfig: ExpandableConfig<GroupedOrgs> = {
        expandedRowKeys: expandedKeys,
        onExpand: (_, record) => {
            onExpandRow(record.id);
        },
        showExpandColumn: false,
        expandedRowRender: (record: any) => (
            <Table
                className="nested-table"
                // style={{ marginLeft: -40, width: "calc(100% + 40px)" }}
                showHeader={false}
                // bordered
                // @ts-ignore
                columns={columns}
                rowKey="id"
                // pagination={{ position: [] }}
                dataSource={record.orgs
                    ?.sort((a: IOrg, b: IOrg) => {
                        if (a.title && b.title) {
                            return a.title.localeCompare(b.title, "da", {
                                sensitivity: "base",
                                numeric: true,
                            });
                        }
                        return 0;
                    })
                    ?.filter((org: any) =>
                        searchText
                            ? org.title?.toLowerCase()?.includes(searchText.toLowerCase())
                            : true,
                    )}
                pagination={false}
            />
        ),
        rowExpandable: record => {
            // @ts-ignore
            return !!record.subGroup;
        },
    };

    if (isAdmin(uid)) {
        return (
            <>
                <Table
                    className="dept-table"
                    bordered
                    // @ts-ignore
                    columns={columns}
                    rowKey="id"
                    expandable={expandedRowConfig}
                    // pagination={{ position: [] }}
                    dataSource={props.data}
                />
                {/* <div style={{ display: "flex", justifyContent: "end", padding: 12 }}>
                    <OrgPagination />
                </div> */}
            </>
        );
    }

    return (
        <>
            {/* <buttodn onClick={() => setExpandedKeys([])}>TEST</button> */}
            <Table
                className="dept-table"
                // @ts-ignore
                columns={columns}
                rowKey="id"
                // expandedRowKeys={expandedKeys}
                // expandRowByClick
                expandable={expandedRowConfig}
                dataSource={props.data}
            />
        </>
    );
}

export default TableList;
