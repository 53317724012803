import React, { useState, useEffect } from "react";
import { Form, Input, Button, Select, Modal, message, Checkbox, InputNumber } from "antd";
import { compose } from "redux";
import { connect } from "react-redux";
import { AppState } from "../../store";
import { post } from "../../general/api";
import { LockOutlined, MailOutlined, UserOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Intl } from "../../general/enums";
import Icon from "@ant-design/icons";
import DaFlag from "../../assets/flags/da";
import GbFlag from "../../assets/flags/gb";
import SeFlag from "../../assets/flags/se";
import DeFlag from "../../assets/flags/de";
import { getPassword } from "../../general/passwordGenerator";
import NlFlag from "../../assets/flags/nl";

const FormItem = Form.Item;
const Option = Select.Option;

const formItemLayout = {
    wrapperCol: { span: 24 },
};

export const prefixSelector = (
    <Form.Item name="phoneCode" noStyle>
        <Select style={{ width: 100 }}>
            <Option value="45">
                <DaFlag style={{ marginBottom: -2 }} /> +45
            </Option>
            <Option value="31">
                <NlFlag style={{ marginBottom: -2 }} /> +31
            </Option>
        </Select>
    </Form.Item>
);

function AddMemberForm({ orgId }: any) {
    const [form] = Form.useForm();
    const [visible, setvisible] = useState(false);
    const [confirmLoading, setconfirmLoading] = useState(false);
    const { t } = useTranslation();
    const [isNewUser, setIsNewUser] = useState<boolean | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [isDanish, setIsDanish] = useState<boolean>(true);
    const [isInvite, setIsInvite] = useState<boolean>(false);

    useEffect(() => {
        form.validateFields();
        return () => {};
    }, []);
    const showModal = () => setvisible(true);

    const handleOk = async (values: any) => {
        setconfirmLoading(true);

        const response = await post("preUserCreation", {
            orgId,
            email: values.email,
            password: values.password,
            role: values.role,
            name: values.name,
            invite: values.invite,
            guides: values.guides,
            intl: values.intl,
            phoneCode: values.phoneCode,
            phone: values.phone,
        });
        if (response.status === 404) {
            setIsNewUser(true);
            setconfirmLoading(false);
            setError(null);
        } else if (response.status === 400 || response.status === 500) {
            setError(t("access.add.error"));
            setconfirmLoading(false);
        } else if (response.status === 200) {
            setconfirmLoading(false);
            setError(null);
            form.resetFields();
            setvisible(false);
            setIsNewUser(null);
            message.success(t("access.add.success"));
        }
    };

    const handleCancel = () => {
        setvisible(false);
    };

    const onLanguageChange = (value: Intl) => {
        const truthy = value === Intl.da_DK;
        setIsDanish(truthy);
    };
    const onInviteChange = (e: any) => setIsInvite(e.target.checked);

    return (
        <>
            <Button type="primary" size="large" onClick={showModal}>
                {t("access.add.button")}
            </Button>
            <Modal
                title={t("access.add.headline")}
                open={visible}
                // onOk={e => handleOk(e, email)}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                footer={[]}>
                <Form
                    autoComplete="false"
                    form={form}
                    initialValues={{
                        password: getPassword(),
                        intl: Intl.da_DK,
                        phoneCode: "45",
                        guides: false,
                    }}
                    onFinish={handleOk}>
                    <FormItem
                        {...formItemLayout}
                        name="email"
                        rules={[
                            { required: true, message: t("access.add.email_required") },
                        ]}>
                        <Input
                            autoComplete="false"
                            role="presentation"
                            type="email"
                            prefix={<MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                            placeholder={t("access.add.email_placeholder")}
                        />
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        style={{ minWidth: "150px" }}
                        name="role"
                        rules={[
                            { required: true, message: t("access.add.role_required") },
                        ]}>
                        <Select placeholder={t("access.add.role_placeholder")}>
                            <Option value="cleaner">{t("access.role_cleaner")}</Option>
                            <Option value="departmentExecutive">
                                {t("access.role_executive")}
                            </Option>
                            <Option value="sales">{t("access.role_sales")}</Option>
                        </Select>
                    </FormItem>
                    {isNewUser && (
                        <FormItem
                            {...formItemLayout}
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: t("access.add.password_required"),
                                },
                            ]}>
                            <Input.Password
                                autoComplete="new-password"
                                // type="password"
                                prefix={
                                    <LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                                }
                                placeholder={t("access.add.password_placeholder")}
                            />
                        </FormItem>
                    )}
                    {isNewUser && (
                        <FormItem
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: t("access.add.name_required"),
                                },
                            ]}>
                            <Input
                                prefix={
                                    <UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                                }
                                placeholder={t("access.add.name_placeholder")}
                            />
                        </FormItem>
                    )}
                    {isNewUser && (
                        <Form.Item
                            name="phone"
                            rules={[
                                {
                                    required: false,
                                    message: "Please input your phone number!",
                                },
                            ]}>
                            <Input
                                addonBefore={prefixSelector}
                                style={{ width: "100%" }}
                                type="tel"
                                maxLength={12}
                                pattern="[0-9]{8}|[0-9]{9}|[0-9]{10}|[0-9]{11}|[0-9]{12}"
                                placeholder={t("access.add.phone_placeholder")}
                            />
                        </Form.Item>
                    )}

                    {isNewUser && (
                        <FormItem name="intl" label={t("access.add.user_language")}>
                            <Select onChange={onLanguageChange}>
                                <Option value={Intl.da_DK}>
                                    <Icon component={DaFlag} /> {t("profile.danish")}
                                </Option>
                                <Option value={Intl.en_US}>
                                    <Icon component={GbFlag} /> {t("profile.english")}
                                </Option>
                                <Option value={Intl.se_SE}>
                                    <Icon component={SeFlag} /> {t("profile.swedish")}
                                </Option>
                                <Option value={Intl.de_DE}>
                                    <Icon component={DeFlag} /> {t("profile.german")}
                                </Option>
                            </Select>
                        </FormItem>
                    )}
                    {isNewUser && (
                        <>
                            <FormItem
                                name="invite"
                                valuePropName="checked"
                                style={{ marginBottom: 0 }}>
                                <Checkbox onChange={onInviteChange}>
                                    {t("access.add.send_automated_invite")}
                                </Checkbox>
                            </FormItem>
                            <FormItem name="guides" valuePropName="checked">
                                <Checkbox disabled={!isDanish || !isInvite}>
                                    {t("access.add.attach_guides_invite")}
                                </Checkbox>
                            </FormItem>
                        </>
                    )}
                    <FormItem>
                        <Button
                            type="primary"
                            block
                            htmlType="submit"
                            loading={confirmLoading}>
                            {t("access.add.submit")}
                        </Button>
                    </FormItem>
                </Form>
                {error && <div style={{ color: "red" }}>{error}</div>}
            </Modal>
        </>
    );
}

function mapStateToProps(state: AppState) {
    return {
        orgId: state.orgs.currentOrg.id,
    };
}

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(AddMemberForm);
