import { CameraOutlined, EyeOutlined, PaperClipOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { Upload, Button, message, Modal, Spin, Tooltip } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import firebase from "../../../firebase";
import { getContentTypeIconMap } from "Views/Reports/Report";
import { RcFile, UploadFile } from "antd/lib/upload/interface";
import confirmModal from "../../../general/confirmModal";
import useGetDownloadUrl from "hooks/useGetDownloadUrl";
import styled from "styled-components";
import { useAppSelector } from "../../../hooks";
import { StyleGuide } from "styles/StyleGuide";
import { ContentType } from "general/enums";
import { getExtentionByMimeType } from "general/files";

interface Props {
    orgId: string;
    contextId: string;
    context: string;
    subContextId?: string; // this is the taskId
    deleteFile: (fileId: string) => void;
}

export default function AuditTaskImages(props: Props) {
    const files = useAppSelector(state =>
        state.files.files
            .map((file: any) => ({
                uid: file.id,
                filePath: file.filePath || file.fileId,
                thumbFilePath: file.thumbFilePath || file.fileId,
                name: file?.fileName || "xxx.png",
                status: "done",
                url: file.thumbnail || file.path,
                taskId: file.subContextId || file.taskId,
                contentType: file.contentType,
            }))

            .filter(file => file.taskId === props.subContextId),
    );
    return (
        <>
            <PicturesWall
                files={files}
                orgId={props.orgId}
                contextId={props.contextId}
                subContextId={props.subContextId}
                context="AUDIT_DOCUMENTATION"
                deleteFile={props.deleteFile}
            />
        </>
    );
}

interface PWProps {
    files: any[];
    orgId: string;
    contextId: string;
    context: string;
    subContextId?: string;
    deleteFile: (fileId: string) => void;
}
type Preview = {
    fileId: string | null;
    contentType: ContentType | null;
    previewVisible: boolean;
    previewImage?: string;
};
function PicturesWall(props: PWProps) {
    console.log({ props });
    const [preview, setPreview] = useState<Preview>({
        previewVisible: false,
        previewImage: "",
        fileId: null,
        contentType: null,
    });
    const [uploading, setUploading] = useState(false);

    const handleCancel = () => setPreview({ ...preview, previewVisible: false });

    const handlePreview = (file: UploadFile<any>) => {
        setPreview({
            fileId: file.uid,
            previewImage: file.url || file.thumbUrl,
            previewVisible: true,
            // @ts-ignore
            contentType: file.contentType,
        });
    };

    const beforeUpload = (file: RcFile) => {
        setUploading(true);
        firebase
            .storage()
            .ref("orgs/" + props.orgId + "/" + props.context)
            // @ts-ignore
            .putFiles(
                [file],
                props.orgId,
                props.contextId,
                props.context,
                props.subContextId,
            )
            .then(function (metadatas: any[]) {
                // Get an array of file metadata
                const stats = {
                    files: metadatas.map(file => file.metadata.name),
                };

                // firebase.firestore().collection(`orgs/${'test'}/issues`).doc('issueId').set(stats)
                // self.setState({
                //     uploading: false,
                // });
                message.success("Fil uploadet");
                return true;
            })
            .catch(function (error: any) {
                console.log(error);
                // self.setState({
                //     uploading: false,
                // });
                return false;
            })
            .finally(function () {
                setUploading(false);
            });
    };

    const { previewVisible, previewImage } = preview;

    const confirmDelete = (fileId: string) => {
        confirmModal({
            title: "Er du sikker på du vil slette billedet?",
            onOk: () => {
                props.deleteFile(fileId);
                setPreview({
                    fileId: null,
                    previewVisible: false,
                    previewImage: undefined,
                    contentType: null,
                });
            },
        });
    };
    console.log({ props });
    console.log({ files: props.files });
    return (
        <div className="custom-audit-file-upload">
            <Upload
                fileList={props.files}
                onPreview={handlePreview}
                beforeUpload={beforeUpload}
                onRemove={file => confirmDelete(file.uid)}
                itemRender={(_, file: any) => {
                    console.log({ fileType: file.contentType });
                    const icon = getContentTypeIconMap(
                        file?.contentType,
                        file?.thumbFilePath,
                        {
                            height: 30,
                            width: 30,
                            borderRadius: 4,
                            // marginTop: 20,
                            fontSize: 55,
                        },
                    );

                    return (
                        <Tooltip title="Se fil">
                            <div>
                                <ViewFile
                                    icon={icon}
                                    filePath={file.filePath}
                                    openPreview={() =>
                                        setPreview({
                                            fileId: file.uid,
                                            previewVisible: true,
                                            previewImage:
                                                file.filePath || file.thumbFilePath,
                                            contentType: file.contentType,
                                        })
                                    }
                                />
                            </div>
                        </Tooltip>
                    );
                }}>
                <Tooltip title="Tilføj filer">
                    <Button loading={uploading} icon={<PaperClipOutlined />}></Button>
                </Tooltip>
            </Upload>
            <Modal
                open={previewVisible}
                cancelText="Luk"
                okText="Slet"
                okType="danger"
                okButtonProps={{ type: "primary", danger: true }}
                onCancel={handleCancel}
                onOk={() => {
                    if (!preview.fileId) return alert("Noget gik galt");
                    confirmDelete(preview.fileId);
                }}>
                {previewImage && <ViewImage preview={preview} />}
            </Modal>
        </div>
    );
}

function ViewImage({ preview }: { preview: Preview }) {
    const { loading, fileUrl } = useGetDownloadUrl(preview?.previewImage as string);
    if (loading) return <div>Loading...</div>;
    if (!fileUrl) return <div>Noget gik galt...</div>;

    if (ContentType.PDF === preview.contentType) {
        return <embed src={fileUrl} type="application/pdf" width="100%" height="600px" />;
    }
    if (
        ContentType.XLSX === preview.contentType ||
        ContentType.DOCX === preview.contentType
    ) {
        const ext = getExtentionByMimeType(preview.contentType);
        return <iframe src={`${fileUrl}${ext}`} width="100%" height="600px"></iframe>;
    }
    return <img alt="example" style={{ width: "100%" }} src={fileUrl} />;
}
function ViewFile({
    icon,
    filePath,
    openPreview,
}: {
    icon: any;
    filePath: string;
    openPreview: VoidFunction;
}) {
    const { fileUrl } = useGetDownloadUrl(filePath);

    if (!fileUrl) return null;
    return (
        <Hovered>
            {icon}
            <Button
                onClick={openPreview}
                type="link"
                style={{
                    position: "absolute",
                    textAlign: "center",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    paddingLeft: "7px",
                }}>
                <EyeOutlined />
            </Button>
        </Hovered>
    );
}

const Hovered = styled("div")`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${StyleGuide.palette.grey300};
    position: relative;
    height: 100%;
    margin-right: 4px;
    transition: all 0.3s;
    cursor: pointer;
    .ant-btn {
        display: none;
    }
    &:hover {
        opacity: 0.8;
        .ant-btn {
            display: block;
        }
    }
`;
