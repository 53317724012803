import { useState } from "react";
import firebase from "firebase/compat/app";
import { useTranslation } from "react-i18next";
import { AuditTemplate } from "../../../modules/auditTemplates/types";

export default function useDuplicateTemplate({
    orgId,
    template,
}: {
    orgId: string;
    template: AuditTemplate;
}) {
    const [loading, setloading] = useState(false);
    const { t } = useTranslation();
    const handleDuplicateTemplate = async () => {
        try {
            setloading(true);
            const snap = await firebase
                .firestore()
                .collection(`orgs/${orgId}/auditTemplates/${template.id}/tasks`)
                .orderBy("createTime", "desc")
                .get();
            const tasks = snap.docs.map(d => {
                const data = d.data();
                return {
                    createTime: data?.createTime,
                    description: data?.description,
                    order: data?.order || null,
                };
            });
            const batch = firebase.firestore().batch();
            const refTemplate = firebase
                .firestore()
                .collection(`orgs/${orgId}/auditTemplates`)
                .doc();
            batch.set(refTemplate, {
                title: `${template.title} (copy)`,
                note: template.note || "",
                guide: template.guide || null,
                auditTemplateCategory: template.auditTemplateCategory || null,
                createTime: new Date(),
            });
            tasks.forEach(task => {
                const refTask = firebase
                    .firestore()
                    .collection(`orgs/${orgId}/auditTemplates/${refTemplate.id}/tasks`)
                    .doc();
                batch.set(refTask, {
                    createTime: task.createTime,
                    description: task.description,
                    order: task.order || null,
                });
            });
            await batch.commit();

            console.log({ tasks });
        } catch (error) {
            console.error(error);
        } finally {
            setloading(false);
        }
    };
    return {
        loading,
        handleDuplicateTemplate,
    };
}
