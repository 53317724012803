import { lazy, Suspense } from "react";
import { Layout } from "antd";
import HomeView from "../Views/Home/container";
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import IssueListContainer from "../Views/Issues/IssueListContainer";
import CreateIssueContainer from "../Views/Issues/CreateIssueContainer";
import IssueDetailContainer from "../Views/Issues/IssueDetailContainer";
import SensorListContainer from "../Views/Sensors/SensorListContainer";
import MemberListContainer from "../Views/Members/MemberListContainer";
import CleanLogListContainer from "../Views/Cleaning/CleanLogListContainer";
import SensorDetailsContainer from "../Views/Sensors/GpsSensorDetailsContainer";
import SensorGPSDetailsContainer from "../Views/Sensors/GpsSimtracking/Container";
import SettingsContainer from "../Views/Settings/Container";
import SignInContainer from "../Views/SignIn/Container";
import ForgotPasswordContainer from "../Views/ForgotPassword/Container";
import AuditTemplateListContainer from "../Views/AuditTemplates/List/AuditTemplateListContainer";
import GroupAuditTemplateListContainer from "../Views/GroupAuditTemplates/List/AuditTemplateListContainer";
import AuditTemplateDetailContainer from "../Views/AuditTemplates/Detail/AuditTemplateDetailContainer";
import AuditTemplateDetailContainerReadOnly from "../Views/AuditTemplates/Detail/AuditTemplateDetailContainerReadOnly";
import GroupAuditTemplateDetailContainer from "../Views/GroupAuditTemplates/AuditTemplateDetailContainer";
import AuditScheduleListContainer from "../Views/AuditSchedule/AuditScheduleListContainer";
import AuditsContainer from "../Views/Audits/AuditListContainer";
import AuditDetailContainer from "../Views/Audits/AuditDetailContainer";
import OrdersContainer from "../Views/Orders/OrderListContainer";
import OrgOrdersContainer from "../Views/OrgOrders/OrderListContainer";
import Tasks from "../Views/Tasks/Container";
import ReportListContainer from "../Views/Reports/ReportListContainer";
import Checkin from "../Views/Checkin/Checkin";
import DirectoryListContainer from "../Views/Directories/DirectoryListContainer";
import AirQualitySensorContainer from "../Views/Sensors/AirQualitySensorContainer";
import AQSensorContainer from "../Views/Sensors/AqSensor/Container";
import ActivityListContainer from "../Views/Activity/ActivityListContainer";
import InventoryView from "../Views/Inventory";
import InventoryTypeContainer from "../Views/Inventory/Container";
import OrgActivityListContainer from "../Views/OrgActivity/OrgActivityListContainer";
import DashboardContainer from "../Views/Dashboard/index";
import Profile from "../Views/Profile/index";
import AdminOnly, { AdminHenningHans, Aks2talOnly } from "../components/AdminOnly";
import GSMPlantContainer from "../Views/Sensors/GsmPlant/Container";
import FeatureControlContainer from "../Views/FeatureControl/Container";
import HourLogsListContainer from "../Views/HourCount/HourLogsListContainer";
import CertificationContainer from "../Views/Certification/CertificationContainer";
import SensorOverviewContainer from "../Views/SensorOverview/Container";
import AdminContainer from "../Views/Admin/Container";
import OrgRoute from "../components/OrgRoute";
import GroupContainer from "../Views/Groups/container";
import IssueOverviewListContainer from "../Views/Issues/IssueOverviewContainer";
import { useAppSelector } from "../hooks";
import Billing from "../Views/Billing/index";
import DrawerBilling from "../Views/Billing/Drawer";
import BillingOverview from "../Views/Billing/Overview";
import PrivacyPolicy from "../Views/PrivacyPolicy";
import NotificationsDrawer from "Views/Members/NotificationDrawer";
import SensorLegend from "Views/Sensors/Legend";
import DashboardOverAllContainer from "Views/DashboardOverAllContainer";
import HeaderBar from "Layout/HeaderBar";
import MemberDrawer from "Views/Members/MemberDrawer";
import OverviewContainer from "Views/Issues/Overview/Container";
import PipedriveContainer from "../Views/Pipedrive/Container";
import GeneralLayout from "Layout/GeneralLayout";
import GSMCustomContainer from "../Views/Sensors/GsmCustom/Container";
import DashboardSubGroup from "Views/DashboardSubGroup";
import AuditReports from "Views/AuditReports";
import PlantsOverview from "Views/Plants";
import GPSOverview from "Views/GPS";
import AgreementDetail from "Views/AgreementDetail";
import { OfferProvider } from "Views/Agreements/Context/OfferContext";
import Sales from "Views/Sales";
const Agreements = lazy(() => import("Views/Agreements"));

interface IPrivateProps {
    component: any;
    // isAuthenticated: boolean;
    path: string;
    exact: boolean;
}

const AdminHenningHansOnlyRoute = ({ component: Component, ...rest }: IPrivateProps) => {
    const isAuthenticated = useAppSelector(state => state.user.details.uid);
    return (
        <Route
            {...rest}
            render={props =>
                isAuthenticated ? (
                    <AdminHenningHans>
                        <OrgRoute>
                            <>
                                <Component {...props} />
                                <div
                                    style={{
                                        position: "fixed",
                                        bottom: 0,
                                        right: 0,
                                        padding: 10,
                                        backgroundColor: "#011529",
                                        color: "#fff",
                                    }}>
                                    Kun aks2tal har adgang her
                                </div>
                            </>
                        </OrgRoute>
                    </AdminHenningHans>
                ) : (
                    <Redirect to="/sign-in" />
                )
            }
        />
    );
};
const Aks2talOnlyRoute = ({ component: Component, ...rest }: IPrivateProps) => {
    const isAuthenticated = useAppSelector(state => state.user.details.uid);
    return (
        <Route
            {...rest}
            render={props =>
                isAuthenticated ? (
                    <Aks2talOnly>
                        <OrgRoute>
                            <>
                                <Component {...props} />
                                <div
                                    style={{
                                        position: "fixed",
                                        bottom: 0,
                                        right: 0,
                                        padding: 10,
                                        backgroundColor: "#011529",
                                        color: "#fff",
                                    }}>
                                    Kun aks2tal har adgang her
                                </div>
                            </>
                        </OrgRoute>
                    </Aks2talOnly>
                ) : (
                    <Redirect to="/sign-in" />
                )
            }
        />
    );
};
const AdminOnlyRoute = ({ component: Component, ...rest }: IPrivateProps) => {
    const isAuthenticated = useAppSelector(state => state.user.details.uid);
    return (
        <Route
            {...rest}
            render={props =>
                isAuthenticated ? (
                    <AdminOnly>
                        <OrgRoute>
                            <>
                                <Component {...props} />
                                <div
                                    style={{
                                        position: "fixed",
                                        bottom: 0,
                                        right: 0,
                                        padding: 10,
                                        backgroundColor: "#011529",
                                        color: "#fff",
                                    }}>
                                    Kun admin har adgang her
                                </div>
                            </>
                        </OrgRoute>
                    </AdminOnly>
                ) : (
                    <Redirect to="/sign-in" />
                )
            }
        />
    );
};
const PrivateRoute = ({ component: Component, ...rest }: IPrivateProps) => {
    const isAuthenticated = useAppSelector(state => state.user.details.uid);
    return (
        <Route
            key={rest.path}
            {...rest}
            render={props =>
                isAuthenticated ? (
                    <OrgRoute>
                        <Component {...props} />
                    </OrgRoute>
                ) : (
                    <Redirect to="/sign-in" />
                )
            }
        />
    );
};
const UserGuard = ({ component: Component, ...rest }: IPrivateProps) => {
    const isAuthenticated = useAppSelector(state => state.user.details.uid);
    const hasGroups = useAppSelector(state => state.groups.groups.length > 0);
    return (
        <Route
            key={rest.path}
            {...rest}
            render={props => {
                if (isAuthenticated && hasGroups) {
                    return <Redirect to="/groups" />;
                }
                if (isAuthenticated && !hasGroups) {
                    return <Redirect to="/depts" />;
                }

                return <Component {...props} />;
            }}
        />
    );
};

export default function Routes() {
    return (
        <Router>
            <div>
                <Layout style={{ minHeight: "100vh" }}>
                    <Suspense fallback={<div>Loading...</div>}>
                        <Switch>
                            {/* <Redirect from="/" to="/sign-in" exact /> */}

                            <UserGuard exact path="/" component={SignInContainer} />
                            <Route
                                exact
                                path="/privacy-policy"
                                component={PrivacyPolicy}
                            />
                            <Route exact path="/sensor-legend" component={SensorLegend} />
                            <UserGuard
                                path="/sign-in"
                                exact
                                component={SignInContainer}
                            />
                            <Route
                                path="/forgot-password"
                                exact
                                component={ForgotPasswordContainer}
                            />
                            <PrivateRoute
                                path="/reports/audits"
                                exact
                                component={AuditReports}
                            />
                            <PrivateRoute path="/depts" exact component={HomeView} />
                            <PrivateRoute
                                path="/groups"
                                exact
                                component={GroupContainer}
                            />
                            <PrivateRoute
                                path="/groups/:groupId/audits/templates"
                                exact
                                component={GroupAuditTemplateListContainer}
                            />
                            <PrivateRoute
                                path="/groups/:groupId/audits/templates/:auditTemplateId"
                                exact
                                component={GroupAuditTemplateDetailContainer}
                            />
                            <PrivateRoute
                                path="/orgs/:orgId/create-issue"
                                exact
                                component={CreateIssueContainer}
                            />
                            <PrivateRoute
                                path="/orgs/:orgId/issues"
                                key="/orgs/:orgId/issues"
                                exact
                                component={IssueListContainer}
                            />
                            <PrivateRoute
                                path="/orgs/:orgId/issues-overview"
                                key="/orgs/:orgId/issues-overview"
                                exact
                                component={OverviewContainer}
                            />
                            <PrivateRoute
                                path="/issues"
                                key="/issues"
                                exact
                                component={IssueOverviewListContainer}
                            />
                            <PrivateRoute
                                path="/orgs/:orgId/issues/:issueId"
                                exact
                                component={IssueDetailContainer}
                            />
                            <PrivateRoute
                                path="/orgs/:orgId/audit/templates"
                                exact
                                component={AuditTemplateListContainer}
                            />
                            <PrivateRoute
                                path="/orgs/:orgId/audit/templates/:auditTemplateId"
                                exact
                                component={AuditTemplateDetailContainer}
                            />
                            <PrivateRoute
                                path="/orgs/:orgId/audit/templates/:auditTemplateId/readonly"
                                exact
                                component={AuditTemplateDetailContainerReadOnly}
                            />
                            <PrivateRoute
                                path="/orgs/:orgId/audit/schedule"
                                exact
                                component={AuditScheduleListContainer}
                            />
                            <PrivateRoute
                                path="/orgs/:orgId/audit/audits"
                                exact
                                component={AuditsContainer}
                            />
                            <PrivateRoute
                                path="/orgs/:orgId/audit/audits/:auditId"
                                exact
                                component={AuditDetailContainer}
                            />
                            <PrivateRoute
                                path="/orgs/:orgId/sensors"
                                exact
                                component={SensorListContainer}
                            />
                            <PrivateRoute
                                path="/orgs/:orgId/sensors/:sensorId"
                                exact
                                component={SensorDetailsContainer}
                            />
                            <PrivateRoute
                                path="/orgs/:orgId/sensors/:sensorId/gps-simtracking"
                                exact
                                component={SensorGPSDetailsContainer}
                            />
                            <PrivateRoute
                                path="/orgs/:orgId/sensors/:sensorId/aq"
                                exact
                                component={AirQualitySensorContainer}
                            />
                            <PrivateRoute
                                path="/orgs/:orgId/sensors/:sensorId/air-quality"
                                exact
                                component={AQSensorContainer}
                            />
                            <PrivateRoute
                                path="/orgs/:orgId/members"
                                exact
                                component={MemberListContainer}
                            />
                            <PrivateRoute
                                path="/orgs/:orgId/dashboard"
                                exact
                                component={DashboardContainer}
                            />
                            <PrivateRoute
                                path="/orgs/:orgId/clean-logs"
                                exact
                                component={CleanLogListContainer}
                            />
                            <PrivateRoute
                                path="/orgs/:orgId/hour-tracker"
                                exact
                                component={HourLogsListContainer}
                            />
                            <PrivateRoute
                                path="/orgs/:orgId/settings"
                                exact
                                component={SettingsContainer}
                            />
                            <PrivateRoute
                                path="/orgs/:orgId/billing"
                                exact
                                component={Billing}
                            />
                            <PrivateRoute
                                path="/orders"
                                exact
                                component={OrdersContainer}
                            />
                            <PrivateRoute
                                path="/orgs/:orgId/orders"
                                exact
                                component={OrgOrdersContainer}
                            />
                            <PrivateRoute path="/tasks" exact component={Tasks} />
                            <AdminOnlyRoute
                                path="/billing"
                                exact
                                component={BillingOverview}
                            />
                            <AdminOnlyRoute
                                path="/sales-overview"
                                exact
                                component={Sales}
                            />
                            <Aks2talOnlyRoute
                                path="/agreements"
                                exact
                                component={Agreements}
                            />
                            {/* <Aks2talOnlyRoute
                                path="/agreements/:agreementId"
                                exact
                                component={AgreementDetail}
                            /> */}
                            <PrivateRoute
                                path="/orgs/:orgId/reports/:directoryId"
                                exact
                                component={ReportListContainer}
                            />
                            <PrivateRoute
                                path="/orgs/:orgId/reports"
                                exact
                                component={DirectoryListContainer}
                            />
                            <PrivateRoute
                                path="/orgs/:orgId/checkin"
                                exact
                                component={Checkin}
                            />
                            <PrivateRoute
                                path="/orgs/:orgId/activity"
                                exact
                                component={OrgActivityListContainer}
                            />
                            <PrivateRoute
                                path="/orgs/:orgId/sensors/:sensorId/gsm-plant"
                                exact
                                component={GSMPlantContainer}
                            />
                            <PrivateRoute
                                path="/orgs/:orgId/sensors/:sensorId/gsm-custom"
                                exact
                                component={GSMCustomContainer}
                            />
                            <PrivateRoute
                                path="/orgs/:orgId/certification"
                                exact
                                component={CertificationContainer}
                            />
                            <PrivateRoute
                                path="/dashboard"
                                exact
                                component={DashboardOverAllContainer}
                            />
                            <PrivateRoute
                                path="/dashboard/sub"
                                exact
                                component={DashboardSubGroup}
                            />
                            <AdminOnlyRoute
                                path="/activity"
                                exact
                                component={ActivityListContainer}
                            />
                            <AdminOnlyRoute
                                path="/inventory"
                                exact
                                component={InventoryView}
                            />
                            <AdminHenningHansOnlyRoute
                                path="/plants-overview"
                                exact
                                component={PlantsOverview}
                            />
                            <AdminOnlyRoute
                                path="/gps-overview"
                                exact
                                component={GPSOverview}
                            />
                            <AdminOnlyRoute
                                path="/inventory/:inventoryType"
                                exact
                                component={InventoryTypeContainer}
                            />
                            <AdminOnlyRoute
                                path="/orgs/:orgId/featureControl"
                                exact
                                component={FeatureControlContainer}
                            />
                            <AdminOnlyRoute
                                path="/sensors"
                                exact
                                component={SensorOverviewContainer}
                            />
                            <AdminOnlyRoute
                                path="/admin"
                                exact
                                component={AdminContainer}
                            />
                            <AdminOnlyRoute
                                path="/pipedrive"
                                exact
                                component={PipedriveContainer}
                            />
                            <Redirect from="*" to="/" />
                        </Switch>
                    </Suspense>
                    <Profile />
                    <DrawerBilling />
                    <NotificationsDrawer />
                    <MemberDrawer />
                </Layout>
            </div>
        </Router>
    );
}
