import React, { useEffect, useState } from "react";
import {
    Map,
    InfoWindow,
    Marker,
    Polyline,
    // @ts-ignore
    Rectangle,
    GoogleApiWrapper,
    Polygon,
    // @ts-ignore
    HeatMap,
} from "google-maps-react";
// import moment from "moment";
import groupBy from "lodash.groupby";
import { format } from "date-fns";
import { Button } from "antd";
// @ts-ignore
import concaveman from "concaveman";
import { SensorType } from "general/enums";
import tinyWasher from "./tiny_washer.png";

const convertPointsToConcavemanFormat = (points: Point[]): [number, number][] => {
    return points.map(point => [point.lng, point.lat]);
};

// Utility function to convert concaveman output back to Point[]
const convertConcavemanToPoints = (points: [number, number][]): Point[] => {
    return points.map(point => ({ lat: point[1], lng: point[0] }));
};
function getDataPoints({ selectedDate, data }: { selectedDate: string; data: any }) {
    const result = groupBy(data, datum => {
        const date = datum.logTime;

        return format(date, "yyyy-MM-dd"); //other;
    });

    const selected = format(new Date(selectedDate), "yyyy-MM-dd"); //`${selectedYear}-${selectedMonth}-${selectDate}`;
    const datapoints = result[selected]
        ? result[selected].map(datum => ({
              lat: Number(datum.lat),
              lng: Number(datum.lng),
          }))
        : [];

    return datapoints;
}

const LoadingContainer = () => <div>Loading map...</div>;
const style = {
    width: "100%",
    height: "100%",
};

const arrow = {
    path: "M 0,0 2,5 -2,5 0,0 z", // 0,0 is the tip of the arrow
    fillColor: "red",
    fillOpacity: 1.0,
    strokeColor: "red",
    strokeWeight: 1,
};
interface Props {
    sensorType?: SensorType;
    google: any;
    centerMap: any;
    selectedDate: any;
    data: any;
    toggleGrid: boolean;
    boundary?: { north: number; south: number; east: number; west: number };
}

export function MapContainer(props: Props) {
    const [play, setPlay] = useState(false); // State to store the path coordinates
    const [currIndex, setCurrentIndex] = useState(0); // State to store the path coordinates
    const [path, setPath] = useState([]); // State to store the path coordinates
    // Center of the map
    // const hull = computeConcaveHull(props.data, 0); // Compute the concave hull for the data points

    const polyLineDataPoints = getDataPoints({
        selectedDate: props.selectedDate,
        data: props.data,
    });
    const filtered = polyLineDataPoints.filter((_, index) => {
        const currentPoint = polyLineDataPoints[index];
        const nextPoint = polyLineDataPoints[index + 1];

        if (
            nextPoint &&
            (currentPoint.lat !== nextPoint.lat || currentPoint.lng !== nextPoint.lng)
        ) {
            return true;
        }

        return false;
    });
    // const stuff = convertPointsToConcavemanFormat(filtered);
    // const concaveHull = convertConcavemanToPoints(concaveman(stuff, 1));
    // console.log({ concaveHull, stuff });

    useEffect(() => {
        let index = currIndex;

        if (!play) {
            return;
        }

        const interval = setInterval(() => {
            if (index < filtered.length) {
                const currentPoint = filtered[index];
                const previousPoint = filtered[index - 1];

                if (
                    previousPoint &&
                    (currentPoint.lat !== previousPoint.lat ||
                        currentPoint.lng !== previousPoint.lng)
                ) {
                    // @ts-ignore
                    setPath(prevPath => [...prevPath, currentPoint]);
                    setCurrentIndex(index);
                }

                index++;
            } else {
                clearInterval(interval);
            }
        }, 100);

        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, [play]);

    // const bounds = {
    //     north: 55.638736,
    //     south: 55.636196,
    //     east: 12.255184,
    //     west: 12.251927,
    // };
    return (
        <>
            {/* hide this on old version */}
            {!props.sensorType && (
                <div style={{ float: "right", marginTop: -50 }}>
                    <Button
                        onClick={() => {
                            setPlay(!play);
                        }}>
                        {play ? "Pause" : "Afspil rute"}
                    </Button>
                    <Button
                        onClick={() => {
                            setPlay(false);
                            setPath([]);
                            setCurrentIndex(0);
                        }}>
                        Nulstil
                    </Button>
                </div>
            )}
            {/* @ts-ignore */}
            <Map
                google={props.google}
                initialCenter={props.centerMap}
                center={props.centerMap}
                zoom={17}
                mapType="satellite"
                style={style}>
                <Polyline
                    path={path?.length < 1 ? filtered : path}
                    strokeColor="red"
                    strokeOpacity={1}
                    strokeWeight={3}
                    icons={[
                        {
                            icon: arrow,
                            offset: "0",
                            repeat: "100px",
                        },
                    ]}
                />
                {path.length > 0 && (
                    <Marker
                        position={path[path.length - 1]}
                        icon={{
                            url: tinyWasher,
                            scaledSize: new google.maps.Size(32, 32),
                        }}
                    />
                )}

                {props.toggleGrid && props.boundary && (
                    <Rectangle
                        fillColor="#0000FF"
                        fillOpacity={0.15}
                        bounds={props.boundary}
                        // strokeColor="#0000FF"
                        // strokeOpacity={0.5}
                        strokeWeight={0}
                    />
                )}
                {/* <HeatMap positions={filtered} opacity={0.6} radius={20} /> */}
                {/* <Polygon
                    paths={concaveHull}
                    strokeColor="green"
                    strokeOpacity={0.8}
                    strokeWeight={2}
                    fillColor="#FF0000"
                    fillOpacity={0.35}
                /> */}
            </Map>
        </>
    );
}

export default GoogleApiWrapper({
    apiKey: "AIzaSyCWLbp3ljXHZhPKg-bfa70BQCuNPNRFY0U",
    LoadingContainer: LoadingContainer,
    // libraries: ["visualization"], // Load the Visualization library
})(MapContainer);
// Define the type for a Point
type Point = {
    lat: number;
    lng: number;
};
